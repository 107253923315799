import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import moment from 'moment';

const initialState = {
  checkIns: [],
  selectedUserCheckIns: [],
  isFetching: false
};

const slice = createSlice({
  name: 'checkIns',
  initialState,
  reducers: {
    getCheckIns(state, action) {
      const { checkIns } = action.payload;

      checkIns.sort((a, b) => {
        if (moment(a.deviceTimestamp).isAfter(moment(b.deviceTimestamp))) {
          return -1;
        }

        if (moment(a.deviceTimestamp).isBefore(moment(b.deviceTimestamp))) {
          return 1;
        }

        return 0;
      });

      state.checkIns = checkIns;
    },
    setCheckIns(state, action) {
      const { checkIns } = action.payload;

      state.checkIns = checkIns;
    },
    getUserCheckIns(state, action) {
      const { checkIns } = action.payload;

      state.selectedUserCheckIns = checkIns;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getCheckIns = (contestId, locationId) => async dispatch => {
  const query = `
    {
      location(id: ${locationId}) {
        checkIns{
          id,
          user{
            firstName,
            lastName,
            participation{
              id
            }
          },
          deviceTimestamp,
          serverTimestamp
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.getCheckIns(response.data.data.location));
  dispatch(slice.actions.setFetching({ isFetching: false }));
};

export const getUserCheckIns = (contestId, userId) => async dispatch => {
  const query = `
      {
        user(id: ${userId}){
          checkIns{
            id,
            serverTimestamp,
            deviceTimestamp,
            deviceOs,
            mean,
            location{
              name,
              points
            }
          }
        }
      }
    `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.getUserCheckIns(response.data.data.user));
  dispatch(slice.actions.setFetching({ isFetching: false }));
};

export const setCheckIns = checkIns => async dispatch => {
  dispatch(slice.actions.setCheckIns({ checkIns }));
};

export const clearCheckIns = () => async dispatch => {
  dispatch(slice.actions.setCheckIns({ checkIns: [] }));
};

export default slice;
