import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  groupTypes: [],
  selectedGroupType: null,
  isFetching: false
};

const slice = createSlice({
  name: 'groupType',
  initialState,
  reducers: {
    getGroupTypes(state, action) {
      const { groupTypes } = action.payload;

      state.groupTypes = groupTypes;
    },
    setGroupTypes(state, action) {
      const { groupTypes } = action.payload;

      state.groupTypes = groupTypes;
    },
    setSelectedGroupType(state, action) {
      const { groupType } = action.payload;

      state.selectedGroupType = groupType;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getGroupTypes = contestId => async dispatch => {
  const query = `
    {
      contest{
        groupTypes {
          id,
          name,
          minParticipants,
          maxParticipants,
          allowUserGroupCreation,
          allowUserChildGroupCreation,
          allowChildUsers,
          description,
          groups {
              id,
              name
          }
          parentGroupTypeId
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getGroupTypes(response.data.data.contest));
};

export const setSelectedGroupType = groupType => async dispatch => {
  dispatch(slice.actions.setSelectedGroupType({ groupType }));
};

export const clearGroupTypes = () => async dispatch => {
  dispatch(slice.actions.setGroupTypes({ groupTypes: [] }));
};

export default slice;
