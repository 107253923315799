import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { Comment } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';
import { getPostReports } from 'src/slices/post_report';

function PostReportsBadge() {
  const dispatch = useDispatch();

  const { postReports } = useSelector(state => state.postReports);

  useEffect(() => {
    dispatch(getPostReports());
  }, [dispatch]);

  return (
    <Badge badgeContent={postReports.length} color="secondary">
      <RouterLink
        to="/app/post_reports"
        style={{
          display: 'flex',
          color: 'inherit',
          textDecoration: 'inherit'
        }}
      >
        <Comment />
      </RouterLink>
    </Badge>
  );
}

export default PostReportsBadge;
