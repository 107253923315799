import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const defaultFilterValues = {
  dateFrom: null,
  dateTo: null,
  dateFor: '',
  numberOfCheckIns: {
    comparator: '>',
    value: null
  },
  differentLocations: {
    comparator: '>',
    value: null
  },
  numberOfPoints: {
    comparator: '>',
    value: null
  },
  showNonMembers: false,
  showMembers: false,
  showCompanyTeams: false,
  showFriendTeams: false,
  showFamilyTeams: false,
  showSinglePerson: false,
  isFilterActive: false
};

const initialState = {
  participants: [],
  selectedParticipant: null,
  filterValues: { ...defaultFilterValues },
  isFetching: false,
  fetched: false
};

const slice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    getParticipants(state, action) {
      const { participations } = action.payload;

      // Hack to make CSV export work.
      // If the nested object does not exist on the 'participation' attribute, the export will fail.
      if (participations) {
        participations.forEach(x => {
          if (!x.ranking) {
            x.ranking = {};
          }

          if (!x.group) {
            x.group = {};
          }
        });
      }

      state.fetched = true;
      state.participants = participations;
    },
    setFetched(state, action) {
      state.fetched = action.payload;
    },
    setParticipants(state, action) {
      const { participants } = action.payload;

      state.participants = participants;
    },
    setSelectedParticipant(state, action) {
      const { participant } = action.payload;

      state.selectedParticipant = participant;
    },
    setFilterValues(state, action) {
      const { filterValues } = action.payload;
      const updated = { ...state.filterValues, ...filterValues };

      state.filterValues = updated;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const setSelectedParticipant = participant => async dispatch => {
  dispatch(slice.actions.setSelectedParticipant({ participant }));
};

export const getParticipants = contestId => async dispatch => {
  dispatch(slice.actions.setFetching({ isFetching: true }));

  // Add paidAmount from participation payments.
  const paymentsResponse = await axios.get(
    `/admin/${contestId}/participation_payments`
  );

  // Add rankings.
  const rankingResponse = await axios.get(`/admin/${contestId}/ranking`);

  const participations = rankingResponse.data.data.map(p => {
    const payment = paymentsResponse.data.data.find(
      pr => pr.participation_id === p.participation.id
    );

    return {
      id: p.participation.id,
      insertedAt: p.participation.inserted_at,
      paidAmount: payment ? payment.amount : null,
      user: {
        id: p.user.id,
        contactInfo: p.user.contact_info,
        firstName: p.user.first_name,
        lastName: p.user.last_name,
        email: p.user.email,
        birthYear: p.user.birth_year,
        phone: p.user.phone,
        profileImage: p.user.profile_image
          ? {
              image: {
                thumbnail: p.user.profile_image.image.thumbnail,
                small: p.user.profile_image.image.small,
                medium: p.user.profile_image.image.medium,
                large: p.user.profile_image.image.large
              }
            }
          : null,
        isPremium: p.user.is_premium
      },
      ranking: {
        ranking: p.ranking,
        totalPoints: p.total_points,
        stats: {
          checkInsCount: p.total_check_ins,
          checkInsAtUniqueLocationsCount: p.total_unique_locations
        }
      },
      price: p.price ? p.price : { name: '' },
      group: p.group
        ? {
            id: p.group.id,
            name: p.group.name,
            groupType: p.group.group_type
              ? {
                  id: p.group.group_type.id,
                  name: p.group.group_type.name
                }
              : null
          }
        : null
    };
  });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getParticipants({ participations }));
};

export const setFilterValues = filterValues => async dispatch => {
  dispatch(slice.actions.setFilterValues({ filterValues }));
};

export const resetFilterValues = () => async dispatch => {
  dispatch(
    slice.actions.setFilterValues({ filterValues: defaultFilterValues })
  );
};

export const clearParticipants = () => async dispatch => {
  dispatch(slice.actions.setFetched(false));
  dispatch(slice.actions.setParticipants({ participants: [] }));
};

export default slice;
