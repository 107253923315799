import moment from 'moment';
import mock from 'src/utils/mock';

let destinations = [
  {
    id: 1,
    name: 'Beiarskaret',
    description:
      'Beiarskaret var et felles møtepunkt for folk fra Beiarn og Misvær under krigen. Det ble holdt gudstjeneste og utvekslet informasjon. Fra 1993 ble det tatt opp igjen og det arrangeres årlig gudstjeneste i begynnelsen av august. Annet hvert år Misvær/Beiarn menighet og historielag. En ny unik pins hvert år med motiv fra fjellfloraen. Her er også musikalsk innslag, bålkos og hyggelig samvær. Følg terrenget/stien i drøye 3km fram til sørøstsiden av Beiarskarvatnet. Fin å kombinere med Indre Lurfjellvatnet for en lengre tur og videre til Grønåsfjellet ved Kobbåvatnet for en tur mellom fjell og bygd. **Lengde t/r:** Drøye 6 km og 100 høydemeter.',
    isActive: true,
    points: 2,
    latitude: '67.034486',
    longitude: '14.895949',
    length: 3050,
    radius: 100,
    altitude: 598,
    waitTimeBetweenCheckIns: 480,
    maxCheckInPerPerson: null,
    maxCheckInPerTeam: null,
    dateFrom: moment()
      .subtract(1, 'days')
      .toDate(),
    dateTo: moment()
      .add(6, 'months')
      .toDate(),
    checkIns: 2,
    destinationImageUrl: 'test.no',
    sponsorImageUrl: 'test.no',
    gpxRoute: '1,2,3'
  },
  {
    id: 2,
    name: 'Blåmoliryggen',
    description:
      'Parkering i grustak eller langs veien/i krysset. Følg traktorvei/sti langs ryggen fra grutaket i nord eller Tverrånes i sør. Utvid turen til en rundtur via Messingmoen eller til en lengre tur med de øvrige postene i området. **Lengde t/r:** Fra 2,5km.',
    isActive: true,
    points: 1,
    latitude: '66.866024',
    longitude: '14.700021',
    length: 1198,
    radius: 100,
    altitude: 174,
    waitTimeBetweenCheckIns: 480,
    maxCheckInPerPerson: null,
    maxCheckInPerTeam: null,
    dateFrom: moment()
      .subtract(1, 'days')
      .toDate(),
    dateTo: moment()
      .add(6, 'months')
      .toDate(),
    checkIns: 5,
    destinationImageUrl: 'test.no'
  },
  {
    id: 3,
    name: 'Brennmoøyra',
    description:
      'Enkel og fin tur langs elva som kan kombineres med elvestien på Storjord til en litt lengre tur. **Lengde:** 1,1km',
    isActive: false,
    points: 1,
    latitude: '66.982132',
    longitude: '14.768161',
    length: 1130,
    radius: 25,
    altitude: 13,
    waitTimeBetweenCheckIns: 480,
    maxCheckInPerPerson: null,
    maxCheckInPerTeam: null,
    dateFrom: moment()
      .subtract(1, 'days')
      .toDate(),
    dateTo: moment()
      .add(6, 'months')
      .toDate()
  }
];

const updateDestination = (id, data) => {
  const index = destinations.findIndex(x => x.id === id);
  const old = destinations.find(x => x.id === id);
  const updated = { ...old, ...data };
  destinations[index] = updated;
};

mock.onGet('/api/destinations').reply(() => {
  return [200, { destinations }];
});

mock.onGet(/\/api\/destinations\/\d+/).reply(config => {
  const array = config.url.split('/');
  const id = parseInt(array.pop(), 10);

  const destination = destinations.find(x => x.id === id);
  return [200, { destination }];
});

mock.onPut(/\/api\/destinations\/\d+/).reply(config => {
  const array = config.url.split('/');
  const id = parseInt(array.pop(), 10);
  const data = JSON.parse(config.data);
  updateDestination(id, data);
  return [200];
});

mock.onPost('/api/destinations/delete').reply(config => {
  const ids = JSON.parse(config.data);
  destinations = destinations.filter(x => ids.indexOf(x.id) === -1);

  return [200];
});

mock.onPost('/api/destinations').reply(config => {
  const data = JSON.parse(config.data);
  data.id = Math.floor(Math.random() * 1000) + 4;
  destinations.push(data);

  return [200];
});
