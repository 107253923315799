import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  participationCodes: [],
  selectedParticipationCode: null,
  isFetching: false
};

const slice = createSlice({
  name: 'participation_code',
  initialState,
  reducers: {
    getParticipationCodes(state, action) {
      const participationCodes = action.payload;

      participationCodes.sort((a, b) => {
        if (a.updated_at < b.updated_at) {
          return 1;
        }

        if (a.updated_at > b.updated_at) {
          return -1;
        }

        return 0;
      });

      state.participationCodes = participationCodes;
    },
    setParticipationCodes(state, action) {
      state.participationCodes = action.payload;
    },
    setSelectedParticipationCode(state, action) {
      state.participationCodes = action.payload;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getParticipationCodes = contestId => async dispatch => {
  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.get(`/admin/${contestId}/participation_codes`);

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getParticipationCodes(response.data.data));
};

export const setSelectedParticipationCode = participationCode => async dispatch => {
  dispatch(slice.actions.setSelectedParticipationCode({ participationCode }));
};

export const clearParticipationCodes = () => async dispatch => {
  dispatch(slice.actions.setParticipationCodes({ participationCodes: [] }));
};

export default slice;
