import moment from 'moment';
import mock from 'src/utils/mock';

const checkIns = [
  {
    id: 1,
    destinationId: 1,
    userName: 'Sofia Jensen',
    date: moment()
      .subtract(5, 'days')
      .hour(14)
      .minute(10)
  },
  {
    id: 2,
    destinationId: 1,
    userName: 'Stig Ovesen',
    date: moment()
      .subtract(3, 'days')
      .hour(19)
      .minute(47)
  },
  {
    id: 3,
    destinationId: 2,
    userName: 'Stig Ovesen',
    date: moment()
      .subtract(1, 'days')
      .hour(17)
      .minute(0)
  },
  {
    id: 4,
    destinationId: 2,
    userName: 'Cecilie Gunvaldsen',
    date: moment()
      .subtract(10, 'days')
      .hour(8)
      .minute(9)
  },
  {
    id: 5,
    destinationId: 2,
    userName: 'Alexander Gulbrandsen',
    date: moment().subtract(5, 'hours')
  },
  {
    id: 6,
    destinationId: 2,
    userName: 'Benjamin Ness',
    date: moment()
      .subtract(6, 'days')
      .hour(21)
      .minute(10)
  },
  {
    id: 7,
    destinationId: 2,
    userName: 'Benjamin Ness',
    date: moment()
      .subtract(2, 'days')
      .hour(17)
      .minute(11)
  }
];

mock.onGet(/\/api\/checkins\/\d+/).reply(config => {
  const array = config.url.split('/');
  const id = parseInt(array.pop(), 10);

  const found = checkIns.filter(x => x.destinationId === id);
  return [200, { checkIns: found }];
});
