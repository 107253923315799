import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  news: [],
  isFetching: false,
  fetched: false,
  selectedLocation: null,
  noMoreNews: false,
  unreadCount: 0
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    getNews(state, action) {
      const news = action.payload;

      state.news = news;
    },
    addNews(state, action) {
      const news = action.payload;
      state.news.unshift(news);
    },
    updateNews(state, action) {
      const news = action.payload;
      state.news = state.news.map(item => (item.id === news.id ? news : item));
    },
    deleteNews(state, action) {
      const { id } = action.payload;
      state.news = state.news.filter(post => post.id !== id);
    },
    setNews(state, action) {
      state.news = action.payload;
    },
    appendNews(state, action) {
      state.news = [...state.news, ...action.payload];
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    },
    setFetched(state, action) {
      state.fetched = action.payload;
    },
    setNoMoreNews(state, action) {
      state.noMoreNews = action.payload;
    },
    setUnreadCount(state, action) {
      state.unreadCount = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getNews = options => async dispatch => {
  const { offset, limit } = options;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const params = new URLSearchParams();
  if (offset) {
    params.append('offset', offset);
  }
  if (limit) {
    params.append('limit', limit);
  }

  const response = await axios.get(
    `/news${params.toString() ? `?${params}` : ''}`
  );

  dispatch(slice.actions.setFetching({ isFetching: false }));
  dispatch(slice.actions.setFetched(true));

  dispatch(slice.actions.setNoMoreNews(response.data.data.length < limit));

  if (offset === 0) {
    dispatch(slice.actions.setNews(response.data.data));
  } else {
    dispatch(slice.actions.appendNews(response.data.data));
  }
};

export const updateNews = news => async dispatch => {
  try {
    const response = await axios.put(`/news/${news.id}`, { news });

    if (response.status !== 200) {
      return response.data.message;
    }

    dispatch(slice.actions.updateNews(response.data));

    return true;
  } catch (e) {
    return e.message;
  }
};

export const addNews = news => async dispatch => {
  try {
    const response = await axios.post('/news', {
      news
    });

    if (response.status !== 201) {
      return response.data.message;
    }

    dispatch(slice.actions.addNews(response.data));

    return true;
  } catch (e) {
    return e.message;
  }
};

export const deleteNews = news => async dispatch => {
  try {
    const response = await axios.delete(`/news/${news.id}`);

    if (response.status !== 204) {
      return response.data.message;
    }

    dispatch(slice.actions.deleteNews(news));

    return true;
  } catch (e) {
    return e.message;
  }
};

export const setUnreadCount = count => async dispatch => {
  dispatch(slice.actions.setUnreadCount(count));
};

export default slice;
