import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { batch, useDispatch } from 'react-redux';

import { clearCheckIns } from 'src/slices/checkins';
import { clearGroups } from 'src/slices/group';
import { clearLocations } from 'src/slices/location';
import { clearParticipants } from 'src/slices/participant';

let preferredTheme;
if (
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches
) {
  preferredTheme = THEMES.ONE_DARK;
} else {
  preferredTheme = THEMES.LIGHT;
}

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: preferredTheme,
  selectedContest: null
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = settings => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
  restoreDefaultSettings: () => {}
});

export const SettingsProvider = ({ settings, children }) => {
  const dispatch = useDispatch();
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (update = {}) => {
    const mergedSettings = _.merge({}, currentSettings, update);

    // If selected contest has changed, clear all redux stores
    if (update.selectedContest) {
      batch(() => {
        dispatch(clearCheckIns());
        dispatch(clearGroups());
        dispatch(clearLocations());
        dispatch(clearParticipants());
      });
    }

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const handleRestoreDefaultSettings = () => {
    setCurrentSettings(defaultSettings);
    storeSettings(defaultSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
        restoreDefaultSettings: handleRestoreDefaultSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
