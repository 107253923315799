import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  postReports: [],
  isFetching: false
};

const slice = createSlice({
  name: 'postReport',
  initialState,
  reducers: {
    getPostReports(state, action) {
      const postReports = action.payload;

      state.postReports = postReports;
    },
    deletePostReport(state, action) {
      const { id } = action.payload;
      state.postReports = state.postReports.filter(
        postReport => postReport.id !== id
      );
    },
    setPostReports(state, action) {
      state.postReports = action.payload;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getPostReports = () => async dispatch => {
  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.get(`/root/post_reports?only_unresolved=1`);

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.setPostReports(response.data.data));
};

export const deletePost = postReport => async dispatch => {
  try {
    const response = await axios.delete(`/posts/${postReport.post.id}`);

    if (response.status !== 204) {
      return response.data.message;
    }

    dispatch(slice.actions.deletePostReport(postReport));

    return true;
  } catch (e) {
    return e.message;
  }
};

export const resolvePostReport = postReport => async dispatch => {
  try {
    const response = await axios.patch(`/root/post_reports/${postReport.id}`, {
      post_report: {
        resolved: true
      }
    });

    if (response.status !== 200) {
      return response.data.message;
    }

    dispatch(slice.actions.deletePostReport(postReport));

    return true;
  } catch (e) {
    return e.message;
  }
};

export default slice;
