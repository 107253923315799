import mock from 'src/utils/mock';

mock.onGet('/api/settings').reply(() => {
  const settings = {
    organizerName: 'Mitt idrettslag',
    about: '',
    extraInfo: '',
    latitude: '67.297501',
    longitude: '14.733200',
    mainColor: '#fb724e',
    email: 'trimpoeng@test.no',
    phone: '99887766',
    homepageUrl: '',
    address: '',
    homepageImageUrl: '',
    logoUrl: '',
    appImageUrl: '',
    instagramHashtag: '',
    instagramUser: '',
    instagramUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    gaId: '',
    mainImageWithoutText: true
  };

  return [200, { settings }];
});
