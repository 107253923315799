export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};

export const LANGUAGES = [
  { language: 'Norsk', key: 'nb' },
  { language: 'English', key: 'en' }
];
