import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItem,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Search as SearchIcon,
  XCircle as XIcon,
  RefreshCw as RefreshIcon,
  Clock as ClockIcon,
  Flag as FlagIcon
} from 'react-feather';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDispatch, useSelector } from 'src/store';
import { getContest, getContests } from 'src/slices/contest';
import { updateAppState } from 'src/slices/app_state';
import {
  common,
  contest as contestStrings,
  searchDrawer
} from 'src/assets/locale';
import useSettings from 'src/hooks/useSettings';
import { getPosts } from 'src/slices/post';
import useAuth from 'src/hooks/useAuth';

const applyFilter = (
  contests,
  query,
  showEnded,
  showPrivate,
  showOngoing,
  showFuture,
  showRegular,
  showActive,
  showInactive
) => {
  return contests.filter(contest => {
    let matches = true;
    const isEnded = moment.utc(contest.dateTo).isBefore(moment.utc());
    const isPrivate = contest.contestOrganizer.userId !== null;
    const isFuture = moment.utc(contest.dateFrom).isAfter(moment.utc());
    const isOngoing =
      moment.utc(contest.dateFrom).isBefore(moment.utc()) &&
      moment.utc(contest.dateTo).isAfter(moment.utc());

    const isActive = contest.active;
    const isInactive = !contest.active;

    if (query) {
      matches =
        contest.name.toLowerCase().includes(query.toLowerCase()) ||
        contest.contestOrganizer.name
          .toLowerCase()
          .includes(query.toLowerCase());
    }

    if (!showEnded && isEnded) {
      matches = false;
    }

    if (!showPrivate && isPrivate) {
      matches = false;
    }

    if (!showOngoing && isOngoing) {
      matches = false;
    }

    if (!showFuture && isFuture) {
      matches = false;
    }

    if (!showRegular && !isPrivate) {
      matches = false;
    }

    if (!showActive && isActive) {
      matches = false;
    }

    if (!showInactive && isInactive) {
      matches = false;
    }

    return matches;
  });
};

const contestsByStatus = contets => {
  const ongoing = [];
  const future = [];
  const ended = [];

  contets.forEach(contest => {
    const from = moment(contest.dateFrom);
    const to = moment(contest.dateTo);
    const now = moment();

    if (
      contest.dateFrom &&
      contest.dateTo &&
      from.isBefore(now) &&
      to.isAfter(now)
    ) {
      ongoing.push(contest);
    } else if (contest.dateFrom && from.isAfter(now)) {
      future.push(contest);
    } else {
      ended.push(contest);
    }
  });

  return {
    ongoing,
    future,
    ended
  };
};

const sortContests = contests => {
  // Sort by dateFrom, then by dateTo, then by name.
  contests.sort((a, b) => {
    const fromA = moment(a.dateFrom);
    const fromB = moment(b.dateFrom);
    const toA = moment(a.dateTo);
    const toB = moment(b.dateTo);

    if (fromA.isBefore(fromB)) {
      return 1;
    }
    if (fromA.isAfter(fromB)) {
      return -1;
    }
    if (toA.isBefore(toB)) {
      return 1;
    }
    if (toA.isAfter(toB)) {
      return -1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  return contests;
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  listWrapper: {
    height: '70vh',
    width: 450
  },
  listElement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5
  },
  contestName: {},
  contestOrganizer: {},
  statusRed: {
    color: theme.palette.error.main
  },
  statusOrange: {
    color: theme.palette.warning.main
  },
  statusGreen: {
    color: theme.palette.success.main
  }
}));

const Search = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contests, isFetching } = useSelector(state => state.contest);
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);

  const [showRegularContests, setRegularContests] = useState(true);
  const [showFutureContests, setFutureContests] = useState(true);
  const [showOngoingContests, setOngoingContests] = useState(true);
  const [showEndedContests, setEndedContests] = useState(false);
  const [showPrivateContests, setPrivateContests] = useState(false);
  const [showActiveContests, setActiveContests] = useState(true);
  const [showInactiveContests, setInactiveContests] = useState(true);

  const { saveSettings } = useSettings();

  const { appState } = useSelector(state => state.appState);
  const preFilteredContests = applyFilter(
    contests,
    query,
    showEndedContests,
    showPrivateContests,
    showOngoingContests,
    showFutureContests,
    showRegularContests,
    showActiveContests,
    showInactiveContests
  );

  const { ongoing, future, ended } = contestsByStatus(preFilteredContests);

  const filteredContests = [
    ...sortContests(ongoing),
    ...sortContests(future),
    ...sortContests(ended)
  ];

  useEffect(() => {
    if (!appState) return;

    if (appState.searchOpen && !isOpen) {
      setOpen(true);
      if (!contests || contests.length === 0) {
        dispatch(getContests());
      }
    } else if (!appState.searchOpen) {
      setOpen(false);
    }
  }, [appState, dispatch, contests, isOpen]);

  const handleOpen = () => {
    dispatch(
      updateAppState({
        searchOpen: true
      })
    );
  };

  const handleClose = () => {
    dispatch(
      updateAppState({
        searchOpen: false
      })
    );
  };

  const handleFutureCheckbox = (event, value) => {
    setFutureContests(value);
  };

  const handleOngoingCheckbox = (event, value) => {
    setOngoingContests(value);
  };

  const handleEndedCheckbox = (event, value) => {
    setEndedContests(value);
  };

  const handlePrivateCheckbox = (event, value) => {
    setPrivateContests(value);
  };

  const handleRegularCheckbox = (event, value) => {
    setRegularContests(value);
  };

  const handleActiveCheckbox = (event, value) => {
    setActiveContests(value);
  };

  const handleInactiveCheckbox = (event, value) => {
    setInactiveContests(value);
  };

  const handleSelectContest = async contest => {
    const res = await getContest(contest.id);

    saveSettings({ selectedContest: res });
    dispatch(
      getPosts({
        contestId: res.id
      })
    );
    dispatch(
      updateAppState({
        searchOpen: false
      })
    );
    history.push('/app');
  };

  const renderIconRow = contest => {
    let color;
    let icon;
    let text;

    const from = moment(contest.dateFrom);
    const to = moment(contest.dateTo);
    const now = moment();

    if (
      contest.dateFrom &&
      contest.dateTo &&
      from.isBefore(now) &&
      to.isAfter(now)
    ) {
      color = classes.statusGreen;
      icon = <RefreshIcon />;
      text = searchDrawer.ongoing;
    } else if (contest.dateFrom && from.isAfter(now)) {
      color = classes.statusOrange;
      icon = <ClockIcon />;
      text = searchDrawer.notStarted;
    } else {
      color = classes.statusRed;
      icon = <FlagIcon />;
      text = searchDrawer.ended;
    }

    return (
      <Box mt={1} display="flex" flexDirection="row">
        <SvgIcon className={clsx(color)} fontSize="small">
          {icon}
        </SvgIcon>
        <Box ml={1} />
        <Typography className={clsx(color)} variant="body2">
          {text}
        </Typography>
        {!contest.active && (
          <>
            <Box ml={1} />
            <Typography className={clsx(classes.statusRed)} variant="body2">
              {contestStrings.inactive}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const renderListItemRow = props => {
    const { index, style } = props;
    const contest = filteredContests[index];

    return contest ? (
      <div key={index} style={style}>
        <ListItem
          className={classes.listElement}
          button
          onClick={() => handleSelectContest(contest)}
        >
          <Box display="flex" flexDirection="column">
            <Typography
              noWrap
              className={classes.contestName}
              title={contest.name}
            >
              {contest.name}
            </Typography>
            <Typography
              noWrap
              className={classes.contestOrganizer}
              title={contest.contestOrganizer.name}
              color="textSecondary"
            >
              {contest.contestOrganizer.userId && (
                <strong>{common.private}: </strong>
              )}
              {contest.contestOrganizer.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {contest.dateFrom
                ? moment(contest.dateFrom).format('DD.MM.YY')
                : ''}
              {' - '}
              {contest.dateTo ? moment(contest.dateTo).format('DD.MM.YY') : ''}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            {renderIconRow(contest)}
          </Box>
        </ListItem>
        <Divider />
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Tooltip title={common.search}>
        <Button startIcon={<SearchIcon />} color="inherit" onClick={handleOpen}>
          {common.searchContests}
        </Button>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                {common.searchContests}
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setQuery(event.target.value)}
                placeholder={searchDrawer.searchContests}
                value={query}
                variant="outlined"
              />
            </Box>
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOngoingContests}
                    onChange={handleOngoingCheckbox}
                    value={showOngoingContests}
                  />
                }
                label={searchDrawer.ongoing}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFutureContests}
                    onChange={handleFutureCheckbox}
                    value={showFutureContests}
                  />
                }
                label={searchDrawer.future}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showEndedContests}
                    onChange={handleEndedCheckbox}
                    value={showEndedContests}
                  />
                }
                label={searchDrawer.endedPlural}
              />
            </Box>
            <Box mt={2}>
              {user && user.isRoot && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showRegularContests}
                        onChange={handleRegularCheckbox}
                        value={showRegularContests}
                      />
                    }
                    label={searchDrawer.regular}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showPrivateContests}
                        onChange={handlePrivateCheckbox}
                        value={showPrivateContests}
                      />
                    }
                    label={searchDrawer.private}
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showActiveContests}
                    onChange={handleActiveCheckbox}
                    value={showActiveContests}
                  />
                }
                label={searchDrawer.active}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInactiveContests}
                    onChange={handleInactiveCheckbox}
                    value={showInactiveContests}
                  />
                }
                label={searchDrawer.inactive}
              />
            </Box>

            <Box mt={4}>
              {isFetching ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <div className={classes.listWrapper}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <FixedSizeList
                        itemCount={filteredContests.length}
                        height={height}
                        width={width}
                        itemSize={85}
                      >
                        {renderListItemRow}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                </div>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
