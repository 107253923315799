import LocalizedStrings from 'react-localization';

const currentSettings = JSON.parse(localStorage.getItem('settings'));
const selectedLanguage = currentSettings?.language || 'nb';

const dashboard = new LocalizedStrings({
  nb: {
    checkInsOverTime: 'Innsjekk over tid',
    latestParticipations: 'Siste påmeldinger',
    latestCheckIns: 'Siste innsjekkinger',
    total: 'totalt',
    noParticipants: 'Ingen påmeldinger',
    contestId: 'Konkurranse-ID',
    type: 'Type',
    free: 'Gratis',
    paid: 'Betalt',
    private: 'Privat'
  },
  en: {
    checkInsOverTime: 'Check-ins over time',
    latestParticipations: 'Latest participations',
    latestCheckIns: 'Latest check-ins',
    total: 'total',
    noParticipants: 'No participations',
    contestId: 'Contest ID',
    type: 'Type',
    free: 'Free',
    paid: 'Paid',
    private: 'Private'
  }
});
dashboard.setLanguage(selectedLanguage);
export { dashboard };

const checkInModal = new LocalizedStrings({
  nb: {
    allCheckIns: 'Alle innsjekk',
    numberOfVisits: 'Antall besøk'
  },
  en: {
    allCheckIns: 'All check-ins',
    numberOfVisits: 'Number of visits'
  }
});
checkInModal.setLanguage(selectedLanguage);
export { checkInModal };

const common = new LocalizedStrings({
  nb: {
    actions: 'Valg',
    active: 'Aktiv',
    activate: 'Aktiver',
    add: 'Legg til',
    all: 'Alle',
    allowed: 'Tillatt',
    archive: 'Arkiv',
    cancel: 'Avbryt',
    change: 'Endre',
    choose: 'Velg',
    close: 'Lukk',
    comment: 'Kommentar',
    configured: 'Konfigurert',
    confirm: 'Bekreft',
    copy: 'Kopier',
    copyToClipboard: 'Kopier til utklippstavle',
    copied: 'Kopiert',
    create: 'Opprett',
    date: 'Dato',
    dates: 'Datoer',
    days: 'Dager',
    deactivate: 'Deaktiver',
    description: 'Beskrivelse',
    delete: 'Slett',
    details: 'Detaljer',
    download: 'Last ned',
    edit: 'Rediger',
    email: 'Epost',
    everything: 'Alt',
    help: 'Hjelp',
    homepage: 'Hjemmeside',
    hours: 'Timer',
    hoursAbbreviation: 't',
    from: 'Fra',
    general: 'Generelt',
    length: 'Lengde',
    inactive: 'Inaktiv',
    information: 'Informasjon',
    kilometers: 'Kilometer',
    language: 'Språk',
    loadMore: 'Last inn flere',
    logout: 'Logg ut',
    minutes: 'Minutter',
    missing: 'Mangler',
    move: 'Flytt',
    moved: 'Flyttet',
    name: 'Navn',
    news: 'Nyheter',
    no: 'Nei',
    none: 'Ingen',
    noMoreNews: 'Ingen flere nyheter',
    noMorePosts: 'Ingen flere innlegg',
    noPosts: 'Ingen innlegg',
    notAllowed: 'Ikke tillatt',
    notConfigured: 'Ikke konfigurert',
    other: 'Annet',
    overview: 'Oversikt',
    password: 'Passord',
    phoneNumber: 'Telefonnummer',
    posts: 'Innlegg',
    price: 'Pris',
    prices: 'Priser',
    private: 'Privat',
    remove: 'Fjern',
    reports: 'Rapporter',
    reset: 'Tilbakestill',
    resetAlt: 'Nullstill',
    resetPassword: 'Nullstill passord',
    required: 'Påkrevd',
    save: 'Lagre',
    search: 'Søk',
    searchContests: 'Velg konkurranse',
    settings: 'Innstillinger',
    sms: 'SMS',
    status: 'Status',
    submitting: 'Sender forespørsel',
    supports: 'Støtter',
    theme: 'Tema',
    time: 'Tid',
    timestamp: 'Tidspunkt',
    to: 'Til',
    unlimited: 'Ubegrenset',
    update: 'Oppdater',
    use: 'Bruk',
    userSearch: 'Brukersøk',
    website: 'Nettside',
    yes: 'Ja'
  },
  en: {
    actions: 'Actions',
    active: 'Active',
    activate: 'Activate',
    add: 'Legg til',
    all: 'All',
    allowed: 'Allowed',
    archive: 'Archive',
    cancel: 'Cancel',
    change: 'Change',
    choose: 'Choose',
    close: 'Close',
    comment: 'Comment',
    confirm: 'Confirm',
    configured: 'Configured',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    copied: 'Copied',
    create: 'Create',
    date: 'Date',
    dates: 'Dates',
    days: 'Days',
    deactivate: 'Deactivate',
    description: 'Description',
    delete: 'Delete',
    details: 'Details',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    everything: 'Everything',
    help: 'Help',
    homepage: 'Homepage',
    hours: 'Hours',
    hoursAbbreviation: 'h',
    from: 'From',
    general: 'General',
    length: 'Length',
    inactive: 'Inactive',
    information: 'Information',
    kilometers: 'Kilometers',
    language: 'Language',
    loadMore: 'Load more',
    logout: 'Log out',
    minutes: 'Minutes',
    missing: 'Missing',
    move: 'Move',
    moved: 'Moved',
    name: 'Name',
    news: 'News',
    no: 'No',
    none: 'None',
    noMoreNews: 'No more news',
    noMorePosts: 'No more posts',
    noPosts: 'No posts',
    notAllowed: 'Not allowed',
    notConfigured: 'Not configured',
    other: 'Other',
    overview: 'Overview',
    password: 'Password',
    phoneNumber: 'Phone number',
    posts: 'Posts',
    price: 'Price',
    prices: 'Prices',
    private: 'Private',
    remove: 'Remove',
    reports: 'Reports',
    reset: 'Reset',
    resetAlt: 'Reset',
    resetPassword: 'Reset password',
    required: 'Required',
    save: 'Save',
    search: 'Search',
    searchContests: 'Choose a contest',
    settings: 'Settings',
    sms: 'SMS',
    status: 'Status',
    submitting: 'Sending request',
    supports: 'Supports',
    theme: 'Theme',
    time: 'Time',
    timestamp: 'Tidspunkt',
    to: 'To',
    unlimited: 'Unlimited',
    update: 'Update',
    use: 'Use',
    userSearch: 'Search users',
    website: 'Website',
    yes: 'Yes'
  }
});
common.setLanguage(selectedLanguage);
export { common };

const contest = new LocalizedStrings({
  nb: {
    errorMessages: {
      genericTitle: 'Feilmelding',
      elevationNotFound:
        'Klarte ikke å finne høydemeter for denne posisjonen. Vær oppmerksom på at denne funksjonen kun fungerer på steder i Norge.'
    },
    activeParticipantFiltersText:
      'Du har aktive filtre som begrenser utvalget av deltakere som vises i listen.',
    removeActiveParticipantFilters: 'Fjern filtre',
    activeHelpText: 'Aktiver eller deaktiver turmålet',
    admin: 'Admin',
    allGroupTypes: 'Alle gruppetyper',
    allLocations: 'Alle turmål',
    allowPostRegistration: 'Tillat manuelle innsjekkinger fra Min Side',
    allParticipants: 'Alle deltakere',
    allTeams: 'Alle grupper',
    allowChildUsersInGroupType: 'Tillat underbrukere i denne gruppetypen',
    allowUserGroupCreation:
      'Tillat brukere å opprette grupper av denne gruppetypen',
    allowUserChildGroupCreation:
      'Tillat brukere å opprette undergrupper til grupper av denne gruppetypen',
    averagePoints: 'Snittpoeng',
    checkIns: 'Innsjekk',
    checkInInterval: 'Ventetid mellom innsjekk',
    checkInImages: 'Deltakerbilder',
    checkInMap: 'Innsjekkingskart',
    checkInWith: 'Sjekk inn med',
    company: 'Bedrift',
    contest: 'Konkurranse',
    contestIsInactiveWarning:
      'Denne konkurransen er inaktiv og vises derfor ikke i appen. Gå til «Innstillinger» for å endre dette.',
    contestActiveDescription:
      'Angir om denne konkurransen er aktiv og skal være synlig i appen eller mulig å melde seg på.',
    contests: 'Konkurranser',
    contestCode: 'Konkurransekode',
    contestCreated: 'Konkurransen er opprettet!',
    contestFromDateHelperText:
      'Konkurransens fra-dato dersom ingen verdi er oppgitt',
    contestToDateHelperText:
      'Konkurransens til-dato dersom ingen verdi er oppgitt',
    contestName: 'Konkurransenavn',
    contestPageCode: 'Konkurransesidekode',
    contestPage: 'Konkurranseside',
    contestTerms: 'Konkurransevilkår',
    contestType: 'Konkurransetype',
    contestUrlId: 'Konkurranse URL-ID',
    coordinates: 'Koordinater',
    copyLocations: 'Hent turmål fra annen konkurranse',
    copyLocationsDescription:
      'Her kan du enkelt kopiere turmål fra andre konkurranser du har tilgang til.',
    createContest: 'Opprett konkurranse',
    createContestSnackbarInfo:
      'Oppretting kan ta litt tid. Du kan trygt navigere bort fra denne siden mens du venter.',
    createLocation: 'Opprett turmål',
    createTeam: 'Opprett gruppe',
    daysLeft: 'Dager igjen',
    deviatingFromDate: 'Avvikende fra-dato',
    deviatingToDate: 'Avvikende til-dato',
    deletedGroup: 'Gruppen er slettet.',
    deleteGroupText: 'Er du sikker på at du vil slette denne gruppen?',
    editLocation: 'Rediger turmål',
    elevation: 'Høydemeter',
    excelineCompanyNumber: 'Exceline bedrift nr.',
    existingContest: 'Eksisterende konkurranse',
    exportCSV: 'Eksporter CSV',
    exportExcel: 'Eksporter Excel',
    findElevation: 'Finn høydemeter',
    groupSelfAdminDeadline: 'Siste dato for selvbetjent gruppeadministrasjon',
    inactive: 'Inaktiv',
    intervalGymCheckIn: 'Minutter mellom gyldige gym innsjekkinger',
    latitude: 'Breddegrad',
    location: 'Turmål',
    locations: 'Turmål',
    locationCreated: 'Turmålet er opprettet',
    locationCheckInCode: 'Turmålskode',
    locationCheckInCodeDescription:
      'Denne koden vil kreves for å kunne sjekke inn manuelt fra konkurransens «Min side».',
    locationDeleteError: 'Det oppstod en feil ved sletting av turmål',
    locationActivatedSuccess: 'Turmålene ble aktivert',
    locationDeactivatedSuccess: 'Turmålene ble deaktivert',
    locationActivationChangeError: 'Det oppstod en feil ved endring av status',
    locationDeleteSuccess: 'Turmålene er slettet',
    locationImage: 'Turmålsbilde',
    locationImageUploadWarning:
      'Du må opprette turmålet før bildet kan lastest opp',
    locationNotification: 'Varsel',
    locationNotificationDescription:
      'Du kan legge til et varsel på dette turmålet dersom det er noe viktig som turgåere bør være oppmerksomme på.',
    locationNotificationAdd: 'Legg til varsel',
    locationNotificationRemove: 'Fjern varsel',
    locationNotificationSeverity: 'Type',
    locationNotificationSeverityDescription: 'Type varsel.',
    locationNotificationSeverityTypeWarning: 'Advarsel',
    locationNotificationSeverityTypeDanger: 'Fare',
    locationNotificationRemovalConfirmation:
      'Er du sikker på at du vil fjerne varselet?',
    locationNotificationPlaceholder: 'Skriv inn varsel',
    locationPlural: 'Turmål',
    locationUpdated: 'Turmålet er oppdatert',
    locationWithQrCode: 'Turmål med QR-kode',
    longitude: 'Lengdegrad',
    goToSettings: 'Gå til innstillinger',
    gpxRoute: 'GPX rute',
    groupclasses: 'Gruppetimer',
    groups: 'Grupper',
    groupType: 'Gruppetype',
    groupTypes: 'Gruppetyper',
    groupTypeAdded: 'Gruppetypen ble opprettet',
    groupTypeUpdated: 'Gruppetypen ble oppdatert',
    groupTypeDeleted: 'Gruppetypen ble slettet',
    locationCopied: 'Turmålet er kopiert.',
    manualCheckIns: 'Manuelle innsjekkinger',
    manualCheckinDeadline: 'Siste dato for manuelle innsjekkinger',
    map: 'Kart',
    maxAge: 'Maks alder',
    maxCheckInHelpText: 'Ubegrenset dersom ingen verdi er oppgitt',
    maxCheckInPerPerson: 'Maks innsjekk per person',
    maxCheckInPerTeam: 'Maks innsjekk per gruppe',
    maxPerDay: 'Maks per dag',
    maxPerWeek: 'Maks per uke',
    minTeamSize: 'Min. gruppestørrelse',
    maxTeamSize: 'Maks. gruppestørrelse',
    mypage: 'Min side',
    minAge: 'Min. alder',
    newGroupType: 'Ny gruppetype',
    newLocation: 'Nytt turmål',
    newParticipant: 'Ny deltaker',
    newTeam: 'Ny gruppe',
    noContestTitle: 'Ingen konkurranse er valgt',
    noContestSubtitle: 'Klikk på søke-ikonet for å velge en konkurranse.',
    noLocationsFound: 'Ingen turmål funnet.',
    note: 'Notat',
    showPostsOnlyFromContest: 'Vis innlegg kun i %CONTEST%',
    optimizeTeamSize: 'Optimiser gruppestørrelse',
    parentGroupType: 'Foreldregruppetype',
    parentOnly: 'Kan ikke velges (kun foreldregruppe)',
    participant: 'Deltaker',
    participants: 'Deltakere',
    participationCodes: 'Deltakerkoder',
    placement: 'Plassering',
    points: 'Poeng',
    pointsChangeWarning: 'NB: poengendring har tilbakevirkende kraft!',
    pointsForGymCheckIn: 'Poeng for gym innsjekkinger',
    precountBooking: 'Precount av bookinger etter start dato',
    radiusForCheckIn: 'Radius for innsjekk',
    rememberCountryCode: 'NB: husk å oppgi landskode!',
    searchAdminUsers: 'Søk administratorer',
    searchCheckInImages: 'Søk etter deltakerbilder',
    searchGroups: 'Søk grupper',
    searchGroupTypes: 'Søk gruppetyper',
    searchLocations: 'Søk turmål',
    searchParticipants: 'Søk deltakere',
    searchParticipationCodes: 'Søk i deltakerkoder',
    searchTransactions: 'Søk i transaksjoner',
    searchTPUsers: 'Søk etter Trimpoeng brukere',
    searchTPUsersPlaceholder: 'Skriv et navn, telefonnummer eller epostadresse',
    searchTPUsersDescription:
      'Her kan du søke etter brukere som har deltatt i minst én av konkurransene du har tilgang til som administrator.',
    sendSMSEmail: 'Send SMS/Epost',
    signupPage: 'Påmeldingsside',
    sponsorWebsiteUrl: 'Sponsor-adresse',
    sponsorImage: 'Sponsorbilde',
    team: 'Gruppe',
    teams: 'Grupper',
    teamCreated: 'Gruppen er opprettet!',
    teamUpdated: 'Gruppen er oppdatert!',
    tooFewParticipantsInGroupWarning: 'Denne gruppen har for få deltakere.',
    transaction: 'Transaksjon',
    transactions: 'Transaksjoner',
    updateLocation: 'Oppdater turmål',
    updateTeam: 'Oppdater gruppe',
    useMyPage: 'Bruk "Min side"',
    useUserIdFrom: 'Ta bruker id fra',
    useContestMainSponsor: 'Bruk konkurransens hovedsponsor',
    usingContestMainSponsor: 'Bruker konkurransens hovedsponsor',
    validBookingStatus: 'Gyldig bookingstatus',
    waitTimeHelpText: 'Ubegrenset dersom ingen verdi er oppgitt',
    websiteType: 'Nettsidetype'
  },
  en: {
    errorMessages: {
      genericTitle: 'Error',
      elevationNotFound:
        'Unable to find an elevation for this location. Please note that this feature is only available for places within Norway.'
    },
    activeParticipantFiltersText:
      'You have active filters that limits the list of participants.',
    removeActiveParticipantFilters: 'Remove filters',
    activeHelpText: 'Activate or deactivate the location',
    admin: 'Admin',
    allGroupTypes: 'All group types',
    allLocations: 'All locations',
    allowPostRegistration: 'Allow manual check-ins from My Page',
    allParticipants: 'All participants',
    allTeams: 'All groups',
    allowChildUsersInGroupType: 'Allow child users in this group type',
    allowUserGroupCreation: 'Allow users to create groups of this group type',
    allowUserChildGroupCreation:
      'Allow users to create child groups within this group type',
    averagePoints: 'Average points',
    checkIns: 'Check-ins',
    checkInInterval: 'Waiting time between check ins',
    checkInImages: 'Check-in images',
    checkInMap: 'Check-in map',
    checkInWith: 'Check-in with',
    company: 'Company',
    contest: 'Contest',
    contestIsInactiveWarning:
      'This contest is inactive and is not visible in the app. Go to «Settings» to change this.',
    contestActiveDescription:
      'Determines if the contest is active and should be open for participations and visible in the app.',
    contests: 'Contests',
    contestCode: 'Contest code',
    contestCreated: 'The contest is created!',
    contestFromDateHelperText: "The contest's from-date if no value is given",
    contestToDateHelperText: "The contest's to-date if no value is given",
    contestName: 'Contest name',
    contestPageCode: 'Contest page code',
    contestPage: 'Contest page',
    contestTerms: 'Contest terms',
    contestType: 'Contest type',
    contestUrlId: 'Contest URL-ID',
    coordinates: 'Coordinates',
    copyLocations: 'Copy locations from another contest',
    copyLocationsDescription:
      'Here you can easily copy locations from other contests you have access to.',
    createContest: 'Create contest',
    createContestSnackbarInfo:
      'Creation can take some time. You can safely navigate away from this page while waiting.',
    createLocation: 'Create location',
    createTeam: 'Create group',
    daysLeft: 'Days left',
    deviatingFromDate: 'Deviating from-date',
    deviatingToDate: 'Deviating to-date',
    deletedGroup: 'The group has been deleted.',
    deleteGroupText: 'Are you sure you want to delete this group?',
    editLocation: 'Edit location',
    elevation: 'Elevation',
    excelineCompanyNumber: 'Exceline company number',
    existingContest: 'Existing contest',
    exportCSV: 'Export CSV',
    exportExcel: 'Export Excel',
    findElevation: 'Find elevation',
    goToSettings: 'Go to settings',
    groupSelfAdminDeadline: 'Final date for manual group administration',
    inactive: 'Inactive',
    intervalGymCheckIn: 'Minutes between valid gym check-ins',
    latitude: 'Latitude',
    location: 'Location',
    locations: 'Locations',
    locationCreated: 'The location is created!',
    locationCopied: 'Location copied.',
    locationCheckInCode: 'Check-in code',
    locationCheckInCodeDescription:
      "This code will be required for manual check-ins on the contest's «My page».",
    locationDeleteError: 'An error occured while deleting locations',
    locationDeleteSuccess: 'The locations are deleted',
    locationActivatedSuccess: 'The locations were activated',
    locationDeactivatedSuccess: 'The locations were deactivated',
    locationActivationChangeError:
      'An error occured while changing location status',
    locationImage: 'Location image',
    locationImageUploadWarning:
      'You must create the location before uploading the image',
    locationNotification: 'Notification',
    locationNotificationDescription:
      "You can add a notification to this location if there's anything important you'd like the user to know about.",
    locationNotificationAdd: 'Add notification',
    locationNotificationRemove: 'Remove notification',
    locationNotificationSeverity: 'Severity',
    locationNotificationSeverityDescription: 'Notification severity.',
    locationNotificationSeverityTypeWarning: 'Warning',
    locationNotificationSeverityTypeDanger: 'Danger',
    locationNotificationRemovalConfirmation:
      'Are you sure you want to remove the notification?',
    locationNotificationPlaceholder: 'Enter notification message',
    locationPlural: 'Locations',
    locationUpdated: 'The location is updated!',
    locationWithQrCode: 'Location with QR code',
    longitude: 'Longitude',
    gpxRoute: 'GPX route',
    groupclasses: 'Group classes',
    groups: 'Groups',
    groupType: 'Group type',
    groupTypes: 'Group types',
    groupTypeAdded: 'Group type added',
    groupTypeUpdated: 'Group type updated',
    groupTypeDeleted: 'Group type deleted',
    manualCheckIns: 'Manual check-ins',
    manualCheckinDeadline: 'Final date for manual check-ins',
    map: 'Map',
    maxAge: 'Max age',
    maxCheckInHelpText: 'Unlimited if no values are given',
    maxCheckInPerPerson: 'Max check in per person',
    maxCheckInPerTeam: 'Max check in per group',
    maxPerDay: 'Max pr. day',
    maxPerWeek: 'Max pr. week',
    minTeamSize: 'Min group size',
    maxTeamSize: 'Max group size',
    mypage: 'My page',
    minAge: 'Min age',
    newGroupType: 'New group type',
    newLocation: 'New location',
    newParticipant: 'New participant',
    newTeam: 'New group',
    noContestTitle: 'No contest is selected',
    noContestSubtitle: 'Click on the search icon to select a contest.',
    noLocationsFound: 'No locations found',
    note: 'Note',
    showPostsOnlyFromContest: 'Only show posts in %CONTEST%',
    optimizeTeamSize: 'Optimize group size',
    parentGroupType: 'Parent Group Type',
    parentOnly: 'Can not be selected (parent group only)',
    participant: 'Participant',
    participants: 'Participants',
    participationCodes: 'Participation codes',
    placement: 'Placement',
    points: 'Points',
    pointsChangeWarning:
      'NB: a change in points will have retroactive consequences',
    pointsForGymCheckIn: 'Points for gym check-ins',
    precountBooking: 'Precount of bookings after start date',
    radiusForCheckIn: 'Radius for check in',
    rememberCountryCode: 'NB: remember country code!',
    searchAdminUsers: 'Search administrators',
    searchCheckInImages: 'Search check-in images',
    searchGroups: 'Search groups',
    searchGroupTypes: 'Search group types',
    searchLocations: 'Search locations',
    searchParticipants: 'Search participants',
    searchParticipationCodes: 'Search participation codes',
    searchTransactions: 'Search transactions',
    searchTPUsers: 'Search for Trimpoeng users',
    searchTPUsersPlaceholder: 'Write a name, phone number or email address',
    searchTPUsersDescription:
      'Here you can search for Trimpoeng users that has participated in at least one of the contests you are an administrator for.',
    sendSMSEmail: 'Send SMS/Email',
    signupPage: 'Signup page',
    sponsorWebsiteUrl: 'Sponsor address',
    sponsorImage: 'Sponsor image',
    team: 'Group',
    teams: 'Groups',
    teamCreated: 'The group is created!',
    teamUpdated: 'The group is updated!',
    tooFewParticipantsInGroupWarning: 'This group has too few participants.',
    transaction: 'Transaction',
    transactions: 'Transactions',
    updateLocation: 'Update location',
    updateTeam: 'Update group',
    useMyPage: 'Use "My page"',
    useUserIdFrom: 'Use user id from',
    useContestMainSponsor: 'Use the contest main sponsor',
    usingContestMainSponsor: 'Using the contest main sponsor',
    validBookingStatus: 'Valid booking status',
    waitTimeHelpText: 'Unlimited if no values are given',
    websiteType: 'Website type'
  }
});
contest.setLanguage(selectedLanguage);
export { contest };

const errorMessages = new LocalizedStrings({
  nb: {
    checkInNotAllowed: 'Det er for kort tid siden forrige innsjekk',
    contestNotOpen: 'Konkurransen er ikke åpen',
    failedToFetchContest: 'Det oppstod en feil ved henting av konkurranse',
    genericError: 'Det oppstod en feil',
    locationClosed: 'Turmålet er stengt',
    locationHasCheckIns: 'Kan ikke slette et turmål med innsjekk',
    locationInactive: 'Turmålet er inaktivt',
    locationMissing: 'Kunne ikke finne turmålet',
    locationNotOpen: 'Turmålet er ikke åpnet for innsjekk',
    missingFields: 'Mangler påkrevde felt',
    noParticipation: 'Ingen deltakelse funnet for denne deltakeren.',
    noUserForPhoneNumber: 'Ingen bruker ble funnet for dette nummeret',
    organizerExists: 'En arrangør med dette navnet finnes fra før',
    contestNameIdExists: 'En konkurranse med samme navn finnes fra før av',
    participantNotPaid: 'Deltakeren har ikke betalt avgiften',
    bulkOperationNoSelection: 'Velg i hvertfall ett element fra listen!'
  },
  en: {
    checkInNotAllowed: 'Too soon since the last check-in',
    contestNotOpen: 'The contest is not open',
    failedToFetchContest: 'An error occured while fetching contest',
    genericError: 'An error occured',
    locationClosed: 'The location is closed',
    locationHasCheckIns: 'Cannot delete a location with check-ins',
    locationInactive: 'The location is inactive',
    locationMissing: 'The location does not exist',
    locationNotOpen: 'The location is not open for check-ins',
    missingFields: 'Missing required fields',
    noParticipation: 'No participation found for this participant',
    noUserForPhoneNumber: 'No user was found for this phone number',
    organizerExists: 'An organizer with this name already exists',
    contestNameIdExists: 'A contest with the same name already exists',
    participantNotPaid: 'The participant have not paid the fee',
    bulkOperationNoSelection: 'Select at least one element from the list!'
  }
});
errorMessages.setLanguage(selectedLanguage);
export { errorMessages };

const fileUpload = new LocalizedStrings({
  nb: {
    click: 'klikk',
    dropHelpText: 'Dra filer hit, eller',
    dropImageHelpText: 'Dra bilde hit, eller klikk for å velge bilde.',
    gpxCardContent:
      'GPX-filen er lastet opp. Du må nå velge "Legg til" for at den skal bli lagt til som ruteforslag. All tidligere ruteinfo vil bli slettet. Det ble totalt lagt til <points> punkter på ruten som har en lengde på <routeLength> meter.',
    gpxCardRouteExists:
      'Det finnes en rute med <points> punkter som er lagret. Klikk her for å erstatte ruten.',
    gpxCardSaveLength: 'Lagre lengden',
    gpxCardUseRoute: 'Bruk rutens siste punkt som turmål',
    gpxHelpText: 'Dra GPX-filen hit, eller klikk for å velge fil.',
    imageDeleteConfirmation: 'Ønsker du å slette dette bildet?',
    imageDeleteError: 'Det oppstod en feil under sletting av bildet',
    imageDeleteSuccess: 'Bildet ble slettet!',
    imageUploadError: 'En feil har oppstått under opplasting',
    imageUploadSuccess: 'Bildet har blitt lastet opp!',
    invalidFile: 'Ugyldig fil eller format',
    pleaseWaitWhileImageIsUploading: 'Vennligst vent mens bildet lastes opp',
    remove: 'Fjern',
    removeAll: 'Fjern alle',
    selectFiles: 'Velg filer',
    toSelect: 'for å velge',
    upload: 'Last opp'
  },
  en: {
    click: 'click',
    dropHelpText: 'Drop files here, or',
    dropImageHelpText: 'Drop image here, or click to select image.',
    gpxCardContent:
      'The GPX-file is uploaded. You must now click "Add" so that the route can be added. All previous route data will be deleted. It was added <points> points to this route with a length of <routeLength> meters.',
    gpxCardRouteExists:
      'A route with <points> points exists. Click here to replace the route.',
    gpxCardSaveLength: 'Save length',
    gpxCardUseRoute: "Use the route's last point as location",
    gpxHelpText: 'Drop the GPX file here, or click to select file.',
    imageDeleteConfirmation: 'Ønsker du å slette dette bildet?',
    imageDeleteError: 'An error occured while deleting the image',
    imageDeleteSuccess: 'The image was deleted!',
    imageUploadError: 'An error occured while uploading the image',
    imageUploadSuccess: 'The image has been uploaded!',
    invalidFile: 'Invalid file or format',
    pleaseWaitWhileImageIsUploading: 'Please wait while the image is uploading',
    remove: 'Remove',
    removeAll: 'Remove all',
    selectFiles: 'Select files',
    toSelect: 'to select',
    upload: 'Upload'
  }
});
fileUpload.setLanguage(selectedLanguage);
export { fileUpload };

const filterModal = new LocalizedStrings({
  nb: {
    activities: 'Aktiviteter',
    dateFor: 'Dato for',
    differentLocations: 'Ulike antall turmål',
    numberOfCheckIns: 'Antall innsjekk',
    numberOfPoints: 'Antall poeng',
    registration: 'Påmelding',
    showCompanyTeams: 'Vis bedriftslag',
    showFamilyTeams: 'Vis familielag',
    showFriendTeams: 'Vis vennelag',
    showMembers: 'Vis medlemmer',
    showNonMembers: 'Vis ikke-medlemmer',
    showSinglePerson: 'Vis enkeltpersoner'
  },
  en: {
    activities: 'Activities',
    dateFor: 'Date for',
    differentLocations: 'Unique number of locations',
    numberOfCheckIns: 'Number of check-ins',
    numberOfPoints: 'Number of points',
    registration: 'Registration',
    showCompanyTeams: 'Show company teams',
    showFamilyTeams: 'Show family teams',
    showFriendTeams: 'Show friend teams',
    showMembers: 'Show members',
    showNonMembers: 'Show non-members',
    showSinglePerson: 'Show single persons'
  }
});
filterModal.setLanguage(selectedLanguage);
export { filterModal };

const helpTexts = new LocalizedStrings({
  nb: {
    clickToSeeCheckIns: 'Klikk for å se innsjekk',
    activate: 'Ønsker du å aktivere',
    deactivate: 'Ønsker du å deaktivere',
    delete: 'Ønsker du å slette',
    'settings.welcomeEmailText':
      'Denne teksten vil vises midt i e-posten som sendes ut til nye deltakere. Etter "Velkommen" og før "Lykke til!"'
  },
  en: {
    clickToSeeCheckIns: 'Click to see check-ins',
    activate: 'Do you wish to activate',
    deactivate: 'Do you wish to deactivate',
    delete: 'Do you wish to delete',
    'settings.welcomeEmailText':
      'This text will be display in the middle of the email that is sent to new participants. After "Welcome" and before "Good luck!"'
  }
});
helpTexts.setLanguage(selectedLanguage);
export { helpTexts };

const logIn = new LocalizedStrings({
  nb: {
    createUser: 'Opprett bruker',
    fromTp1:
      'Du har nå blitt videresendt til den nye administrasjonssiden for Trimpoeng. Vi håper du liker den!',
    logIn: 'Logg inn',
    signInText: 'Vennligst logg inn for å bruke administrasjonssiden.',
    termsReadAndAccepted: 'Jeg har lest og akseptert vilkårene',
    userRequestText:
      'Du har havnet her fordi noen har lagt deg til som administrator. Hvis du har en Trimpoeng-bruker fra før av kan du logge inn for å bekrefte forespørselen. Har du ikke en Trimpoeng-bruker må du opprette en ny bruker.',
    welcome: 'Velkommen!'
  },
  en: {
    createUser: 'Create user',
    fromTp1:
      "You've now been redirected to the new administration page for Trimpoeng. We hope you like it!",
    logIn: 'Log in',
    signInText: 'Please log in to use the administration page.',
    termsReadAndAccepted: 'I have read and accepted the terms',
    userRequestText:
      "You've been sent here because someone added you as an administrator. If you already have a Trimpoeng user you can just log in to approve the request. If not, you must create a user before approving the request.",
    welcome: 'Welcome!'
  }
});
logIn.setLanguage(selectedLanguage);
export { logIn };

const mapModal = new LocalizedStrings({
  nb: {
    choosePosition: 'Velg posisjon for turmålet',
    dragMarker:
      'Klikk i kartet for å tegne ruten. Dra eksisterende markører for å endre ruten.',
    dragToChangeRadius:
      'Dra i de hvite punktene på sirkelen for å endre radius.',
    routeMode: 'Rutemodus',
    searchPlaceholder: 'Søk etter sted'
  },
  en: {
    choosePosition: 'Choose the posision for the location',
    dragMarker:
      'Click within the map to draw the route. Drag existing marker to change route.',
    dragToChangeRadius: 'Drag the white dots on the sircle to change radius.',
    routeMode: 'Route mode',
    searchPlaceholder: 'Search for location'
  }
});
mapModal.setLanguage(selectedLanguage);
export { mapModal };

const moveParticipantModal = new LocalizedStrings({
  nb: {
    noTeam: 'Ingen gruppe'
  },
  en: {
    noTeam: 'No team'
  }
});
moveParticipantModal.setLanguage(selectedLanguage);
export { moveParticipantModal };

const participant = new LocalizedStrings({
  nb: {
    activity: 'Aktivitet',
    address: 'Adresse',
    assignCode: 'Tildel kode',
    birthYear: 'Fødselsår',
    countryCode: 'Landskode',
    activityDeleted: 'Aktiviteten er slettet.',
    activityDeleteError: 'Det oppstod en feil under sletting av aktiviteten.',
    checkInDeleted: 'Innsjekket er slettet.',
    checkInDeleteError: 'Det oppstod en feil under sletting av innsjekk',
    checkInPoints: 'Innsjekkspoeng',
    childUsers: 'Tilhørende brukere',
    childParent: 'Administreres av',
    createParticipant: 'Opprett deltaker',
    deleteActivity:
      'Ønsker du å fjerne <name> - <date> fra deltakeren? Dette kan ikke gjøres om.',
    deleteCheckIn:
      'Ønsker du å fjerne <location> - <date> fra deltakeren? Dette kan ikke gjøres om.',
    existingParticipant: 'En deltaker med dette telefonnummeret finnes fra før',
    extraPoints: 'Ekstrapoeng',
    extraPointsRegisteredText: 'Ekstrapoeng er registrert.',
    extraPointsRegistrationError: 'Det oppstod en feil ved tildeling av poeng',
    female: 'Kvinne',
    filtering: 'Filtrering',
    firstName: 'Fornavn',
    gender: 'Kjønn',
    giveExtraPoints: 'Gi ekstrapoeng',
    lastActive: 'Sist aktiv',
    lastName: 'Etternavn',
    male: 'Mann',
    manualCheckIn: 'Manuelt innsjekk',
    manualCheckInRegisteredText: 'Innsjekk er registrert.',
    manualCheckInError: 'Det oppstod en feil registrering av innsjekk',
    member: 'Medlem',
    membership: 'Medlemskap',
    moveParticipantError: 'Det oppstod en feil under flytting av deltakeren(e)',
    moveParticipantSuccess: 'Flyttet deltaker(e)',
    paidAmount: 'Betalt beløp',
    participantCode: 'Deltakerkode',
    participantCodeError: 'Det oppstod en feil ved tildeling av deltakerkode',
    participantCodeModalText:
      'Er du sikker på at du vil tildele en deltakerkode til denne deltakeren? Deltakeren vil da få tilgang til konkurransen uten å betale.',
    participantCodeSuccess: 'Deltakeren har fått tildelt en kode.',
    participantCreated: 'Deltakeren er opprettet!',
    participantCreatedError:
      'Det oppstod en feil under opprettelsen av deltakeren',
    participantDeleted: 'Deltakeren er fjernet',
    participantDeletedError: 'Det oppstod en feil under fjerning av deltakeren',
    phone: 'Telefon',
    phoneNumber: 'Telefonnummer',
    registered: 'Påmeldt',
    registeredDate: 'Påmeldt dato',
    registeredFor: 'Påmeldt på',
    registeredFrom: 'Registrert fra',
    registeredOn: 'Registrert på',
    removeFromContestText:
      'Ønsker du å fjerne <userName> fra <contestName>? Husk at alle poeng mistes.',
    signupFields: 'Påmeldingsfelter',
    surname: 'Etternavn',
    totalPoints: 'Totalpoeng',
    trips: 'Turer',
    uniqueTrips: 'Ulike turer',
    uniqueTripsAverage: 'Ulike turer (snitt)',
    username: 'Brukernavn'
  },
  en: {
    activity: 'Activity',
    address: 'Address',
    assignCode: 'Assign code',
    birthYear: 'Birth year',
    countryCode: 'Country code',
    activityDeleted: 'The activity is deleted.',
    activityDeleteError: 'An error occured while deleting the activity.',
    checkInDeleted: 'The check-in is deleted.',
    checkInDeleteError: 'An error occured while deleting the check-in',
    checkInPoints: 'Check-in points',
    childUsers: 'Child users',
    childParent: 'Parent user',
    createParticipant: 'Create participant',
    deleteActivity:
      'Do you wish to remove <name> - <date> from the participant? This action cannot be undone.',
    deleteCheckIn:
      'Do you wish to remove <location> - <date> from the participant? This action cannot be undone.',
    existingParticipant: 'A participant with this phone number already exists',
    extraPoints: 'Extra points',
    extraPointsRegisteredText: 'Extra points are registered.',
    extraPointsRegistrationError:
      'An error occured while assigning extra points',
    female: 'Female',
    filtering: 'Filtering',
    firstName: 'First name',
    gender: 'Gender',
    giveExtraPoints: 'Give extra points',
    lastActive: 'Last active',
    lastName: 'Last name',
    male: 'Male',
    manualCheckIn: 'Manual check-in',
    manualCheckInRegisteredText: 'Check-in is registered.',
    manualCheckInError: 'An error occured while registering the check-in',
    member: 'Member',
    membership: 'Membership',
    moveParticipantError: 'An error occured while moving the participant(s)',
    moveParticipantSuccess: 'Moved participant(s)',
    paidAmount: 'Paid amount',
    participantCode: 'Participant code',
    participantCodeError:
      'An error occurred while assigning a participant code',
    participantCodeModalText:
      'Are you sure you wish to assign a participant code to this participant? The participant will then get access to the contest without paying.',
    participantCodeSuccess: 'The participant have been assigned a new code',
    participantCreated: 'The participant is created!',
    participantCreatedError: 'An error occured while creating the participant',
    participantDeleted: 'The participant is removed',
    participantDeletedError: 'An error occured while removing the participant',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    registered: 'Registered',
    registeredDate: 'Registered date',
    registeredFor: 'Registered for',
    registeredFrom: 'Registered from',
    registeredOn: 'Registered on',
    removeFromContestText:
      'Do you wish to remove <userName> from <contestName>? Remember that all points will be lost.',
    signupFields: 'Signup fields',
    surname: 'Surname',
    totalPoints: 'Total points',
    trips: 'Trips',
    uniqueTrips: 'Unique trips',
    uniqueTripsAverage: 'Unique trips (avg)',
    username: 'Username'
  }
});
participant.setLanguage(selectedLanguage);
export { participant };

const priceDeleteModal = new LocalizedStrings({
  nb: {
    content: 'Ønsker du å slette denne prisen?'
  },
  en: {
    content: 'Do you wish to delete this price?'
  }
});
priceDeleteModal.setLanguage(selectedLanguage);
export { priceDeleteModal };

const promotion = new LocalizedStrings({
  nb: {
    tryAppText: 'Prøv appen!'
  },
  en: {
    tryAppText: 'Try the app!'
  }
});
promotion.setLanguage(selectedLanguage);
export { promotion };

const resetPasswordModal = new LocalizedStrings({
  nb: {
    emailGenericError: 'Det oppstod en feil ved utsending av epost',
    missingEmailError: 'Deltakeren mangler epostadresse',
    missingPhoneError: 'Deltakeren mangler telefonnnummer',
    modalBodyText:
      'Her kan du nullstille passordet til denne deltakeren. Du vil da få opp en firesifret kode som du kan videreformidle til deltakeren. Du kan også velge om du vil sende ut en epost og/eller en SMS med det nye passordet.',
    newPasswordText: 'Det nye passordet er',
    resetError: 'Det oppstod en feil ved nullstilling av passord',
    sendEmail: 'Send passord per epost',
    sentEmail: 'Sendt per epost til',
    sendSMS: 'Send passord per SMS (belasted arrangør ihht. avtale)',
    sentSMS: 'Sendt per SMS til',
    smsGenericError: 'Det oppstod en feil ved utsending av sms'
  },
  en: {
    emailGenericError: 'An error occured while sending the email',
    missingEmailError: 'The contestant is missing an email address',
    missingPhoneError: 'The contestant is missing a phone number',
    modalBodyText:
      'Here you can reset the password for this contestant. You will then be shown a four digit code that you can convey to the participant. You can also choose if you wish to send an email and/or an SMS with the new password.',
    newPasswordText: 'The new password is',
    resetError: 'An error occured while resetting the password',
    sendEmail: 'Send password as email',
    sentEmail: 'Sendt email to',
    sendSMS:
      'Send password as SMS (charges contest organizer according to agreement)',
    sentSMS: 'Sendt SMS to',
    smsGenericError: 'An error occured while sending sms'
  }
});
resetPasswordModal.setLanguage(selectedLanguage);
export { resetPasswordModal };

const searchDrawer = new LocalizedStrings({
  nb: {
    ended: 'Avsluttet',
    notStarted: 'Ikke startet',
    searchContests: 'Søk konkurranser',
    ongoing: 'Pågående',
    future: 'Fremtidige',
    endedPlural: 'Avsluttede',
    private: 'Private',
    regular: 'Vanlige',
    active: 'Aktive',
    inactive: 'Inaktive',
    showEndedContests: 'Vis avsluttede konkurranser',
    showPrivateContests: 'Vis private konkurranser'
  },
  en: {
    ended: 'Ended',
    notStarted: 'Not started',
    ongoing: 'Ongoing',
    future: 'Future',
    endedPlural: 'Ended',
    private: 'Private',
    regular: 'Regular',
    active: 'Active',
    inactive: 'Inactive',
    searchContests: 'Search contests',
    showEndedContests: 'Show ended contests',
    showPrivateContests: 'Show private contests'
  }
});
searchDrawer.setLanguage(selectedLanguage);
export { searchDrawer };

const settings = new LocalizedStrings({
  nb: {
    aboutContest: 'Om konkurransen',
    advancedSettings: 'Avanserte innstillinger',
    adminUsers: 'Administratorer',
    appImage: 'App - startbilde',
    automaticSMS: 'Automatiske SMS',
    contactInfo: 'Kontaktinformasjon',
    disabledContactTrimpoeng:
      'Ta kontakt med Trimpoeng for å endre denne innstillingen.',
    contestTermsSubtitle: 'Hvis utfylt må alle deltakere godta vilkårene',
    copyLocations: 'Kopier turmål',
    copyOldContest: 'Kopier en gammel konkurranse',
    childUsers: 'Underbrukere',
    childUserRegistration: 'Registrering av underbrukere',
    chooseColor: 'Velg farge',
    allowChildUserRegistration: 'Tillat registrering av underbrukere',
    allowChildUserRegistrationDescription:
      'Hvis denne er deaktivert kan ikke underbrukere registreres i denne konkurransen.',
    emailLogo: 'Logo for epost',
    externalContestPage: 'Ekstern konkurranseside',
    externalContestPageDescription:
      'Dersom du ønsker å erstatte vår konkurranseside med din egen, legg inn den fulle adressen til siden her.',
    externalSignupPage: 'Ekstern påmeldingsside',
    externalSignupPageDescription:
      'Dersom du har en ekstern påmeldingsside kan du legge inn den fulle adressen til siden her.',
    extraInfo: 'Ekstra informasjon under konkurransenavn',
    frontPageImage: 'Forsidebilde',
    globalNotice: 'Globalt varsel',
    globalNoticeSeverity: 'Type',
    globalNoticeSeverityInfo: 'Informasjon',
    globalNoticeSeverityWarning: 'Advarsel',
    globalNoticeDescription:
      'Dersom du ønsker å legge til et globalt varsel til konkurransesiden så kan du skrive beskjeden her.',
    locations: 'Turmål',
    restrictLocationDetails:
      'Vis turmålsdetaljer (beskrivelse, rute, bilde osv.) kun til deltakere i konkurransen.',
    mainColor: 'Hovedfarge',
    newParticipationDataType: 'Nytt felt',
    editParticipationDataType: 'Endre felt',
    organizer: 'Arrangør',
    organizerName: 'Arrangørnavn',
    priceCreated: 'Prisen er opprettet!',
    priceDeleted: 'Prisen er slettet',
    priceUpdated: 'Prisen er oppdatert!',
    requireParticipationCodeOrPayment: 'Krev deltakerkode',
    requireParticipationCodeOrPaymentDescription:
      'Krev deltakerkode for å delta i konkurransen hvis det ikke finnes priser.',
    settingsUpdated: 'Innstillingene er oppdatert!',
    signupSettings: 'Påmeldingsfelter',
    showHideAdvanced: 'Vis/skjul avanserte innstillinger',
    showMainImageWithoutText: 'Vis hovedbilde uten tekst',
    socialMedia: 'Sosiale medier',
    sortOrder: 'Sorteringsrekkefølge',
    sortOrders: {
      total_points: 'Antall poeng',
      total_check_ins: 'Antall innsjekk',
      total_unique_locations: 'Antall unike turmål',
      total_unique_locations_total_check_ins:
        'Antall unike turmål, deretter antall innsjekk',
      user_last_name: 'Etternavn'
    },
    sponsorTitle: 'Hovedsponsor',
    sponsorDescription:
      'Du kan angi en hovedsponsor for konkurransen. Det vil vises en klikkbar logo forskjellige steder i appen. Kontakt Trimpoeng for å endre denne innstillingen. Merk at denne funksjonen er en betalt tilleggstjeneste.',
    sponsorWebsiteUrl: 'Adresse til nettsted',
    sponsorLogo: 'Sponsorlogo',
    sponsorLogoBackgroundColor: 'Bakgrunnsfarge for sponsorlogo',
    sortOrderDescription: 'Velg standard sorteringsrekkefølge i poengoversikt.',
    uploadLogo: 'Last opp logo',
    website: 'Nettside',
    websiteLogo: 'Logo for nettside',
    welcomeEmailText: 'Ekstra tekst i e-post sendt til nye deltakere'
  },
  en: {
    aboutContest: 'About the contest',
    adminUsers: 'Administrators',
    advancedSettings: 'Advanced settings',
    appImage: 'App - start image',
    automaticSMS: 'Automatic SMS',
    contactInfo: 'Contact information',
    disabledContactTrimpoeng: 'Contact Trimpoeng to change this setting.',
    childUsers: 'Child users',
    childUserRegistration: 'Child user registration',
    chooseColor: 'Choose color',
    allowChildUserRegistration: 'Allow child user registration',
    allowChildUserRegistrationDescription:
      'If disabled, child users can not be registered in this contest.',
    contestTermsSubtitle:
      'If filled out, all participants must accept these terms',
    copyLocations: 'Copy locations',
    copyOldContest: 'Copy an old contest',
    emailLogo: 'Logo for email',
    externalContestPage: 'External contest page',
    externalContestPageDescription:
      'If you wish to replace our contest page with your own, enter the full address of the page here.',
    extraInfo: 'Extra information under contest name',
    frontPageImage: 'Front page image',
    globalNotice: 'Global Notice',
    globalNoticeSeverity: 'Type',
    globalNoticeSeverityInfo: 'Information',
    globalNoticeSeverityWarning: 'Warning',
    globalNoticeDescription:
      'If you wish to add a global notice to the contest page you can do so by typing your message here.',
    mainColor: 'Main color',
    locations: 'Locations',
    restrictLocationDetails:
      'Only show location details to participating users.',
    newParticipationDataType: 'Add field',
    editParticipationDataType: 'Edit field',
    organizer: 'Organizer',
    organizerName: 'Organizer name',
    priceCreated: 'The price is created!',
    priceDeleted: 'The price is deleted',
    priceUpdated: 'The price is updated!',
    requireParticipationCodeOrPayment: 'Require participation code',
    requireParticipationCodeOrPaymentDescription:
      'Require a participation code if there are no prices.',
    settingsUpdated: 'The settings are updated!',
    signupSettings: 'Signup fields',
    showHideAdvanced: 'Show/hide advanced settings',
    showMainImageWithoutText: 'Show main image without text',
    socialMedia: 'Social media',
    sortOrder: 'Sort order',
    sortOrderDescription: 'Select default sort order in ranking lists.',
    sortOrders: {
      total_points: 'Points',
      total_check_ins: 'Check-ins',
      total_unique_locations: 'Unique locations',
      total_unique_locations_total_check_ins:
        'Unique locations, then check-ins',
      user_last_name: 'Last name'
    },
    sponsorTitle: 'Main sponsor',
    sponsorDescription:
      'You can set a sponsor for your contest. A clickable logo will be displayed in different places in the app. Contact Trimpoeng to change this setting. Note that this is a paid feature.',
    sponsorWebsiteUrl: 'Website URL',
    sponsorLogo: 'Sponsor logo',
    sponsorLogoBackgroundColor: 'Sponsor logo background color',
    uploadLogo: 'Upload logo',
    website: 'Website',
    websiteLogo: 'Logo for website',
    welcomeEmailText: 'Extra text in email sent to new participants'
  }
});
settings.setLanguage(selectedLanguage);
export { settings };

const smsEmailModal = new LocalizedStrings({
  nb: {
    criticalInformation:
      'Dette er tjenestekritisk informasjon. Send også til deltakere som ikke har samtykket til kommunikasjon.',
    errorMessage: 'Det oppstod en feil ved sending',
    message: 'Melding',
    noMessage: 'Du har ikke skrevet en beskjed.',
    recipient: 'Mottaker',
    recipients: 'Mottakere',
    sentMessageTo: 'Din melding har blitt sendt til',
    smsInfo: 'Husk at SMS medfører kostnader (se konkurranseavtalen).',
    subject: 'Emne'
  },
  en: {
    criticalInformation:
      'This is critical information. Send also to participants who have not consented to communication.',
    errorMessage: 'An error occured while sending the message',
    message: 'Message',
    noMessage: 'You have not written a message',
    recipient: 'Recipient',
    recipients: 'Recipients',
    sentMessageTo: 'Your message have been sent to',
    smsInfo:
      'Remember that sending SMS messages will incur costs (see the competition agreement).',
    subject: 'Subject'
  }
});
smsEmailModal.setLanguage(selectedLanguage);
export { smsEmailModal };

const userModal = new LocalizedStrings({
  nb: {
    editUserInfo: 'Rediger brukerinfo',
    updateError: 'Det oppstod en feil ved oppdatering av brukeren',
    updateSuccess: 'Brukeren har blitt oppdatert!'
  },
  en: {
    editUserInfo: 'Edit user info',
    updateError: 'An error occured while updating the user',
    updateSuccess: 'The user has been updated!'
  }
});
userModal.setLanguage(selectedLanguage);
export { userModal };

const temporaryPasswordModal = new LocalizedStrings({
  nb: {
    andIsValidUntil: 'og er gyldig til kl.',
    createTemporaryPassword: 'Opprett midlertidig passord',
    createError: 'Det oppstod en feil ved oppretting av midlertidig passord.',
    createSuccess: 'Midlertidig passord opprettet!',
    email: 'E-post',
    phone: 'Mobilnummer',
    reason: 'Hvorfor trenger du et midlertidig passord?',
    theTemporaryPasswordIs: 'Det midlertidige passordet er'
  },
  en: {
    andIsValidUntil: 'and is valid until',
    createTemporaryPassword: 'Create temporary password',
    createError: 'An error occured while creating a temporary password.',
    createSuccess: 'Temporary password created!',
    email: 'Email',
    phone: 'Phone',
    reason: 'Why do you need a temporary password?',
    theTemporaryPasswordIs: 'The temporary password is'
  }
});
temporaryPasswordModal.setLanguage(selectedLanguage);
export { temporaryPasswordModal };

const validation = new LocalizedStrings({
  nb: {
    contestName: 'Konkurransenavn er påkrevd',
    countrycode: 'Landskode er påkrevd',
    date: 'Dato er påkrevd',
    email: 'Epost er påkrevd',
    firstName: 'Fornavn er påkrevd',
    invalidDate: 'Ugyldig dato',
    invalidEmail: 'Ugyldig epostadresse',
    lastName: 'Etternavn er påkrevd',
    latitude: 'Breddegrad er påkrevd',
    location: 'Turmål er påkrevd',
    longitude: 'Lengdegrad er påkrevd',
    name: 'Navn er påkrevd',
    organizer: 'Arrangør er påkrevd',
    password: 'Passord er påkrevd',
    phone: 'Telefonnummer er påkrevd',
    points: 'Poeng er påkrevd',
    price: 'Pris er påkrevd',
    radius: 'Radius er påkrevd',
    reason: 'Årsaken er påkrevd',
    surname: 'Etternavn er påkrevd',
    termsAccepted: 'Du må lese og akseptere vilkårene',
    fieldStringMax:
      'For langt. Dette feltet tillater ikke mer enn <fieldLimit> symboler.',
    fieldStringEmail: 'Må være en gyldig epostadresse',
    fieldNumberInteger: 'Verdien må være et heltall',
    fieldNumberMin: 'For lav verdi. Kan ikke være mindre enn <fieldLimit>.',
    fieldNumberMax: 'For høy verdi. Kan ikke være mer enn <fieldLimit>.',
    fieldNumberPositive: 'Verdien må være positiv',
    fieldDateValid: 'Ugyldig datoformat',
    fieldDateMin: 'Datoen er utenfor minimumsgrensen',
    fieldDateMax: 'Datoen er utenfor maksimumsgrensen'
  },
  en: {
    contestName: 'Contest name is required',
    countrycode: 'Country code is required',
    date: 'Date is required',
    email: 'Email is required',
    firstName: 'First name is required',
    invalidDate: 'Invalid date',
    invalidEmail: 'Invalid email address',
    lastName: 'Last name is required',
    latitude: 'Latitude is required',
    location: 'Location is required',
    longitude: 'Longitude is required',
    name: 'Name is required',
    organizer: 'Organizer is required',
    password: 'Password is required',
    phone: 'Phone number is required',
    points: 'Points are required',
    price: 'Price is required',
    radius: 'Radius is required',
    reason: 'A reason is required',
    surname: 'Surname is required',
    termsAccepted: 'You must read and accept the terms',
    fieldStringMax:
      'Too long. This field does not permit more than <fieldLimit> characters.',
    fieldStringEmail: 'Must be a valid email address',
    fieldNumberInteger: 'The value must be an integer',
    fieldNumberMin: 'The value is too low. Can not be less than <fieldLimit>.',
    fieldNumberMax: 'The value is too high. Can not be more than <fieldLimit>.',
    fieldNumberPositive: 'The value must be positive',
    fieldDateValid: 'Invalid date format',
    fieldDateMin: 'This date exceeds the minimum limit',
    fieldDateMax: 'This date exceeds the maximum limit'
  }
});
validation.setLanguage(selectedLanguage);
export { validation };

const transaction = new LocalizedStrings({
  nb: {
    cartSum: 'SUM',
    cartItemName: 'Tekst',
    cartItemQuantity: 'Antall',
    cartItemPrice: 'Pr. stk.',
    cartItemSum: 'Totalt',
    downloadReceipt: 'Last ned kvittering',
    sendCopyToBuyer: 'Send kopi til kjøper',
    receiptSent: 'Kvittering sendt til kjøper',
    receiptDownloadError: 'Det oppstod en feil ved nedlasting av kvittering',
    receiptSendError: 'Det oppstod en feil ved sending av kvittering',
    paymentId: 'Betalings-ID',
    transactionUserName: 'Kjøper',
    transactionStatus: 'Status',
    transactionAmount: 'Beløp',
    transactionLastUpdated: 'Endret'
  },
  en: {
    cartSum: 'SUM',
    cartItemName: 'Text',
    cartItemQuantity: 'Quantity',
    cartItemPrice: 'Per item',
    cartItemSum: 'Total',
    downloadReceipt: 'Download receipt',
    sendCopyToBuyer: 'Send copy to buyer',
    receiptSent: 'Receipt sent to buyer',
    receiptDownloadError: 'An error occured while downloading the receipt',
    receiptSendError: 'An error occured while sending the receipt',
    paymentId: 'Payment ID',
    transactionUserName: 'Buyer',
    transactionStatus: 'Status',
    transactionAmount: 'Amount',
    transactionLastUpdated: 'Updated'
  }
});
transaction.setLanguage(selectedLanguage);
export { transaction };

const participationCode = new LocalizedStrings({
  nb: {
    batch: 'Batch',
    code: 'Kode',
    codeHelperText: 'La stå tomt for å generere kode automatisk.',
    codes: 'Koder',
    create: 'Opprett',
    createSuccess: 'Deltakerkode(r) opprettet!',
    createError: 'Det skjedde en veil ved oppretting av deltakerkode(r)',
    edit: 'Endre',
    expiresAt: 'Utløper',
    exportCSV: 'Eksporter utvalg til CSV',
    exportExcel: 'Eksporter utvalg til Excel',
    free: 'Gratis',
    freeParticipationLabel: 'Deltakerkoden gir gratis deltakelse',
    priceParticipationLabel: 'Deltakerkoden gir tilgang til egen pris',
    createNewPrice: 'Opprett ny pris',
    priceName: 'Navn på pris',
    priceAmount: 'Beløp',
    chooseExistingPrice: 'Velg eksisterende pris',
    chooseExistingPriceWarning:
      'Ved å velge en eksisterende pris vil ikke lenger denne være mulig å velge for andre deltakere.',
    joined: 'Påmeldt',
    lastUpdated: 'Endret',
    name: 'Navn',
    numCodes: 'Antall koder',
    numSlots: 'Antall deltakere per kode',
    participations: 'Deltakelser',
    price: 'Pris',
    slots: 'Antall',
    userName: 'Navn'
  },
  en: {
    batch: 'Batch',
    code: 'Code',
    codeHelperText: 'Leave empty to generate code automatically.',
    codes: 'Codes',
    create: 'Create',
    createSuccess: 'Participant code(s) created successfully',
    createError: 'Unable to create participant code(s)',
    exportCSV: 'Export batch to CSV',
    exportExcel: 'Export batch to Excel',
    freeParticipationLabel: 'Participation code gives free access',
    priceParticipationLabel: 'Participation code gives access to locked price',
    createNewPrice: 'Create new price',
    priceName: 'Price name',
    priceAmount: 'Amount',
    chooseExistingPrice: 'Choose existing price',
    chooseExistingPriceWarning:
      'By choosing an existing price this price will no longer be available for other participants.',
    edit: 'Edit',
    expiresAt: 'Expires at',
    free: 'Free',
    joined: 'Joined',
    lastUpdated: 'Edited',
    name: 'Name',
    numCodes: 'Number of codes',
    numSlots: 'Number of participants per code',
    participations: 'Participations',
    price: 'Price',
    slots: 'Quantity',
    userName: 'Name'
  }
});
participationCode.setLanguage(selectedLanguage);
export { participationCode };

const adminUsers = new LocalizedStrings({
  nb: {
    accessLevel: 'Tilgangsnivå',
    accessLevel100: 'Konkurranse',
    accessLevel200: 'Arrangør',
    accessLevel300: 'Bedrift',
    accessLevelExplanation:
      'Brukeren kan få tilgang til denne konkurransen, alle konkurransene til denne arrangøren eller alle konkurransene til denne bedriften.',
    addUserExplanation:
      'Det vil bli sendt en e-post til den e-postadressen du oppgir her med en aktiveringslenke i. Den nye administratoren må klikke på lenken og logge inn eller opprette en ny bruker.',
    adminUsers: 'Administratorer',
    deleteAdminUser: 'Fjern administrator',
    deleteAdminUserText:
      'Er du sikker på at du vil fjerne denne brukeren som administrator?',
    logInAndConfirm: 'Logg inn og bekreft',
    createUserAndConfirm: 'Opprett bruker og bekreft',
    user: 'administrator',
    userAdded:
      'En forespørsel har blitt sendt til %EMAIL%. Når vedkommende eventuelt godtar forespørselen vil administratoren dukke opp her.',
    userCreated: 'Bruker opprettet',
    userCreatedText:
      'Brukeren din er opprettet og du vil i løpet av kort tid motta en SMS og en e-post med brukernavn og passord. Klikk på «Logg inn» ovenfor og logg inn med brukeren din for å fullføre.',
    userCreationError: 'Det skjedde en feil ved forsøk på å legge til brukeren',
    userDeleted: 'Brukeren ble fjernet som administrator for konkurransen'
  },
  en: {
    accessLevel: 'Access level',
    accessLevel100: 'Contest',
    accessLevel200: 'Contest Organizer',
    accessLevel300: 'Company',
    accessLevelExplanation:
      "The user will have access to either this contest, all of this organizer's contests or all of this company's contests.",
    addUserExplanation:
      'An email will be sent to the provided email address with an activation link.',
    adminUsers: 'Users',
    deleteAdminUser: 'Remove administrator',
    deleteAdminUserText:
      'Are you sure you want to remove this user as an administrator?',
    logInAndConfirm: 'Log in and confirm',
    createUserAndConfirm: 'Create user and confirm',
    user: 'user',
    userAdded:
      'A request has been sent to %EMAIL%. Once it has been approved, the user will be displayed in this list',
    userCreated: 'User created',
    userCreatedText:
      'Your user has been created. You will shortly receive an SMS and an email with your username and password. Click «Log in» above and log in to approve the request.',
    userCreationError: 'An error occured when trying to add the user',
    userDeleted: 'The user was removed as an administrator for the contest'
  }
});
adminUsers.setLanguage(selectedLanguage);
export { adminUsers };

const reports = new LocalizedStrings({
  nb: {
    checkIns: 'Innsjekkinger',
    downloadExcel: 'Eksporter til Excel',
    rankings: 'Poengoversikt',
    check_ins: 'Innsjekkinger',
    locationName: 'Turmålsnavn',
    locations: 'Turmål',
    premiumUsers: 'Premium-brukere',
    timePeriods: {
      today: 'I dag',
      yesterday: 'I går',
      thisWeek: 'Denne uken',
      prevWeek: 'Forrige uke',
      thisMonth: 'Denne måneden',
      prevMonth: 'Forrige måned',
      everything: 'Alt'
    },
    total_points: 'Antall poeng',
    total_unique_locations: 'Antall ulike turmål',
    total_check_ins: 'Antall innsjekkinger',
    order_by: 'Rangér etter',
    unique_users: 'Ulike deltakere',
    posts: 'Innlegg'
  },
  en: {
    checkIns: 'Check ins',
    downloadExcel: 'Export to Excel',
    rankings: 'Ranking',
    locationName: 'Location name',
    locations: 'Locations',
    premiumUsers: 'Premium Users',
    timePeriods: {
      today: 'Today',
      yesterday: 'Yesterday',
      thisWeek: 'This week',
      prevWeek: 'Previous week',
      thisMonth: 'This month',
      prevMonth: 'Previous month',
      everything: 'Everything'
    },
    total_points: 'Points',
    total_unique_locations: 'Unique locations',
    total_check_ins: 'Check ins',
    order_by: 'Rank by',
    unique_users: 'Unique users',
    posts: 'Posts'
  }
});
reports.setLanguage(selectedLanguage);
export { reports };

const checkInImages = new LocalizedStrings({
  nb: {
    checkInImage: 'Deltakerbilde',
    checkInImages: 'Deltakerbilder',
    checkInImageDeleted: 'Deltakerbildet ble slettet.',
    checkInImageNotDeleted: 'Deltakerbildet ble ikke slettet.',
    confirmDelete: 'Er du sikker på at du vil slette dette bildet?',
    deleteCheckInImageTitle: 'Slett deltakerbilde',
    deleteCheckInImageText: 'Er du sikker på at du vil slette dette bildet?',
    noCheckInImagesFound: 'Ingen bilder har blitt lastet opp ennå.',
    noMatchingCheckInImagesFound: 'Ingen bilder samsvarer med søket ditt.',
    showing: 'Viser',
    of: 'av',
    showMore: 'Vis flere',
    perPage: 'Per side',
    allLocations: 'Alle turmål',
    selectLocation: 'Turmål',
    allUsers: 'Alle deltakere',
    selectUser: 'Deltaker'
  },
  en: {
    checkInImage: 'Check-in image',
    checkInImages: 'Check-in images',
    checkInImageDeleted: 'Check-in image deleted.',
    checkInImageNotDeleted: 'Check-in image not deleted.',
    confirmDelete: 'Are you sure you want to delete this image?',
    deleteCheckInImageTitle: 'Delete check-in image',
    deleteCheckInImageText: 'Are you sure you want to delete this image?',
    noCheckInImagesFound: 'No images have been uploaded yet.',
    noMatchingCheckInImagesFound: 'No matching images found.',
    showing: 'Showing',
    of: 'of',
    showMore: 'Show more',
    perPage: 'Per page',
    allLocations: 'All locations',
    selectLocation: 'Location',
    allUsers: 'All users',
    selectUser: 'User'
  }
});
checkInImages.setLanguage(selectedLanguage);
export { checkInImages };

const signupFields = new LocalizedStrings({
  nb: {
    chooseType: 'Velg type',
    chooseTypeDescription: 'Angi hvilken type felt dette skal være.',
    deleteTitle: 'Er du sikker på at du vil slette dette feltet?',
    deleteDescription:
      'Dersom du sletter dette feltet vil all data knyttet til dette feltet også bli slettet.',
    description:
      'Her kan du definere ekstra felter som kan eller må fylles ut ved påmelding.',
    name: 'Navn',
    type: 'Type',
    fieldDeleted: 'Feltet er slettet.',
    fieldSaved: 'Feltet er lagret.',
    sortOrderUpdated: 'Sorteringsrekkefølgen er oppdatert.'
  },
  en: {
    chooseType: 'Choose type',
    chooseTypeDescription: 'Specify what type of field this should be.',
    deleteTitle: 'Are you sure you want to delete this field?',
    deleteDescription:
      'If you delete this field, all data associated with this field will also be deleted.',
    description:
      'Here you can define extra fields that can or must be filled out upon registration.',
    name: 'Name',
    type: 'Type',
    fieldDeleted: 'The has been deleted.',
    fieldSaved: 'The field has been saved.',
    sortOrderUpdated: 'Sort order updated.'
  }
});
signupFields.setLanguage(selectedLanguage);
export { signupFields };

const qrCodes = new LocalizedStrings({
  nb: {
    add: 'Legg til QR-kode for innsjekking',
    addText:
      'Hvis du legger til en QR-kode for innsjekking vil det bli generert en QR-kode som deltakerne må skanne for å sjekke inn på turmålet. Merk at det ikke vil være mulig å sjekke inn uten å skanne QR-koden.',
    touched: 'Du har ulagrede endringer. Husk å lagre turmålet.',
    delete: 'Slett QR-kode',
    deleteText:
      'Er du sikker på at du vil slette denne QR-koden? Vær oppmerksom på at det vil bli mulig å sjekke inn på turmålet uten å skanne en QR-kode. Dersom du senere oppretter en ny QR-kode vil den forrige koden ikke lenger fungere.',
    created: 'QR-koden er opprettet!',
    print: 'Skriv ut',
    saveLocationFirst: 'Du må lagre turmålet før du kan generere en QR-kode.',
    qrCode: 'QR-kode'
  },
  en: {
    add: 'Add QR code for check-in',
    addText:
      'By adding a QR code for check-in, a QR code will be generated that participants must scan to check in at the location. Note that it will not be possible to check in without scanning the QR code.',
    touched: 'You have unsaved changes. Remember to save the location.',
    delete: 'Delete QR code',
    deleteText:
      'Are you sure you want to delete this QR code? Note that it will be possible to check in at the location without scanning a QR code. If you later create a new QR code, the previous code will no longer work.',
    created: 'The QR code is created!',
    print: 'Print',
    saveLocationFirst:
      'You must save the location before you can generate a QR code.',
    qrCode: 'QR code'
  }
});
qrCodes.setLanguage(selectedLanguage);
export { qrCodes };
