/**
 * This is the translation file for the material UI theme builder. Since Material UI does not have
 * proper support for Norwegian, one had to be created.
 *
 * To expand this, check out the API docs for each component at https://material-ui.com/api/{component_name}
 */

const nbNO = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Vis linje'
    },
    MuiTablePagination: {
      labelRowsPerPage: 'Rader pr. side',
      nextIconButtonText: 'Neste side',
      backIconButtonText: 'Forrige side',
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} av ${count !== -1 ? count : `flere enn ${to}`}`
    },
    MuiAutocomplete: {
      clearText: 'Tøm',
      closeText: 'Avslutt',
      loadingText: 'Laster...',
      noOptionsText: 'Ingen treff',
      openText: 'Åpne'
    },
    MuiAlert: {
      closeText: 'Avslutt'
    },
    MuiPagination: {
      'aria-label': 'Pagination navigasjon',
      getItemAriaLebel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Gå til '}side ${page}`;
        }
        if (type === 'first') {
          return 'Gå til første side';
        }
        if (type === 'last') {
          return 'Gå til siste side';
        }
        if (type === 'next') {
          return 'Gå til neste side';
        }
        if (type === 'previous') {
          return 'Gå til forrige side';
        }
        return undefined;
      }
    }
  }
};

export default nbNO;
