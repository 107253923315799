import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Chip,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import moment from 'moment';
import { contest as contestStrings } from 'src/assets/locale';
import useAuth from 'src/hooks/useAuth';
import PostsBadge from 'src/views/posts_badge';
import NewsBadge from 'src/views/news/NewsBadge';
import Account from './Account';
import Search from './Search';
import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { settings } = useSettings();

  const { user } = useAuth();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {Boolean(settings && settings.selectedContest) && (
            <Box ml={2}>
              <Link
                color="inherit"
                component={RouterLink}
                to="/app/contest"
                variant="h3"
              >
                {settings.selectedContest.name}
                {!settings.selectedContest.active && (
                  <Box display="inline-block" ml={1}>
                    <Chip
                      size="small"
                      label={contestStrings.inactive}
                      color="default"
                      style={{ backgroundColor: 'red', color: 'white' }}
                    />
                  </Box>
                )}
              </Link>
              {Boolean(settings.selectedContest.contestOrganizer) && (
                <Typography variant="body2">
                  {moment(settings.selectedContest.dateFrom).format(
                    'DD.MM.YYYY'
                  )}{' '}
                  –{' '}
                  {moment(settings.selectedContest.dateTo).format('DD.MM.YYYY')}{' '}
                  ({settings.selectedContest.contestOrganizer.name})
                </Typography>
              )}
            </Box>
          )}
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Search />
        <Settings />
        <Box ml={1}>
          <NewsBadge />
        </Box>
        {user && user.isRoot && (
          <Box ml={3}>
            <PostsBadge />
          </Box>
        )}
        <Box ml={4}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
