import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { NotificationImportant } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';
import { getNews, setUnreadCount } from 'src/slices/news';

function NewsBadge() {
  const dispatch = useDispatch();

  const { news, unreadCount } = useSelector(state => state.news);

  useEffect(() => {
    dispatch(getNews({ offset: 0, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    const newsLatestTwoWeeks = news.filter(n => {
      const newsDate = new Date(n.created_at);
      const today = new Date();
      const twoWeeksAgo = new Date(today.setDate(today.getDate() - 14));
      return newsDate > twoWeeksAgo;
    }).length;

    const latestReadNewsId = window.localStorage.getItem('latestReadNewsId');
    if (latestReadNewsId) {
      const count = news.filter(n => n.id > latestReadNewsId).length;
      dispatch(setUnreadCount(count));
    } else {
      dispatch(setUnreadCount(newsLatestTwoWeeks));
    }
  }, [news, dispatch]);

  return (
    <Badge badgeContent={unreadCount} color="error">
      <RouterLink
        to="/app/news"
        style={{
          display: 'flex',
          color: 'inherit',
          textDecoration: 'inherit'
        }}
      >
        <NotificationImportant />
      </RouterLink>
    </Badge>
  );
}

export default NewsBadge;
