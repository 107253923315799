import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  posts: [],
  isFetching: false,
  fetched: false,
  selectedLocation: null,
  noMorePosts: false
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    getPosts(state, action) {
      const posts = action.payload;

      state.posts = posts;
    },
    deletePost(state, action) {
      const { id } = action.payload;
      state.posts = state.posts.filter(post => post.id !== id);
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },
    appendPosts(state, action) {
      state.posts = [...state.posts, ...action.payload];
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    },
    setFetched(state, action) {
      state.fetched = action.payload;
    },
    setNoMorePosts(state, action) {
      state.noMorePosts = action.payload;
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    }
  }
});

export const { reducer } = slice;

export const setSelectedLocationForPosts = location => async dispatch => {
  dispatch(slice.actions.setSelectedLocation(location));
};

export const getPosts = options => async dispatch => {
  const { contestId, offset, limit } = options;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const params = new URLSearchParams();
  if (offset) {
    params.append('offset', offset);
  }
  if (limit) {
    params.append('limit', limit);
  }

  const response = contestId
    ? await axios.get(
        `/admin/${contestId}/posts${params.toString() ? `?${params}` : ''}`
      )
    : await axios.get(`/posts${params.toString() ? `?${params}` : ''}`);

  dispatch(slice.actions.setFetching({ isFetching: false }));
  dispatch(slice.actions.setFetched(true));

  dispatch(slice.actions.setNoMorePosts(response.data.data.length < limit));

  if (offset === 0) {
    dispatch(slice.actions.setPosts(response.data.data));
  } else {
    dispatch(slice.actions.appendPosts(response.data.data));
  }
};

export const deletePost = post => async dispatch => {
  try {
    const response = await axios.delete(`/posts/${post.post.id}`);

    if (response.status !== 204) {
      return response.data.message;
    }

    dispatch(slice.actions.deletePost(post));

    return true;
  } catch (e) {
    return e.message;
  }
};

export default slice;
