import React, { useCallback, useEffect, useRef } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/nb';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import createTheme from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { getContest } from './slices/contest';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings, saveSettings } = useSettings();

  const selectedContestId = useRef(null);

  const palette =
    settings.selectedContest?.collectionId === 2
      ? {
          primary: {
            main: '#26886E'
          },
          secondary: {
            main: '#26886E'
          }
        }
      : {
          primary: {
            main: '#ef6c00'
          },
          secondary: {
            main: '#ef6c00'
          }
        };

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    palette
  });

  moment.locale(settings.language || 'nb');

  const maybeUpdateContest = useCallback(async () => {
    if (selectedContestId.current) {
      const contest = await getContest(selectedContestId.current);
      saveSettings({
        selectedContest: contest
      });
    }
  }, [selectedContestId, saveSettings]);

  useEffect(() => {
    if (!settings) return;

    if (settings?.selectedContest?.id !== selectedContestId.current) {
      selectedContestId.current = settings?.selectedContest?.id;
      maybeUpdateContest();
    }
  }, [settings, maybeUpdateContest]);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={settings.language || 'nb'}
        >
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
