import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const defaultFilterValues = {
  isFilterActive: false
};

const initialState = {
  transactions: [],
  selectedTransaction: null,
  filterValues: { ...defaultFilterValues },
  isFetching: false
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    getTransactions(state, action) {
      const { transactions } = action.payload;

      state.transactions = transactions;
    },
    setTransactions(state, action) {
      const { transactions } = action.payload;

      state.transactions = transactions;
    },
    setSelectedTransaction(state, action) {
      const { transaction } = action.payload;

      state.selectedTransaction = transaction;
    },
    setFilterValues(state, action) {
      const { filterValues } = action.payload;
      const updated = { ...state.filterValues, ...filterValues };

      state.filterValues = updated;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getTransactions = contestId => async dispatch => {
  const query = `
  {
    contest{
        transactions {
            id,
            contest {
              id
            }
            amount,
            user {
                id,
                firstName,
                lastName
            },
            cart {
                id,
                cartItems {
                    id,
                    price {
                        id,
                        name,
                        price,
                        minimumAge,
                        maximumAge
                    },
                    quantity,
                    amount,
                    text
                }
            }
            status,
            paymentId,
            insertedAt,
            updatedAt
        }
    }
  }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(
    slice.actions.getTransactions(response.data.data.contest.transactions)
  );
};

export const setSelectedTransaction = transaction => async dispatch => {
  dispatch(slice.actions.setSelectedTransaction({ transaction }));
};

export const setFilterValues = filterValues => async dispatch => {
  dispatch(slice.actions.setFilterValues({ filterValues }));
};

export const resetFilterValues = () => async dispatch => {
  dispatch(
    slice.actions.setFilterValues({ filterValues: defaultFilterValues })
  );
};

export const clearTransactions = () => async dispatch => {
  dispatch(slice.actions.setTransactions({ transactions: [] }));
};

export default slice;
