import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as adminUserReducer } from 'src/slices/admin_user';
import { reducer as locationReducer } from 'src/slices/location';
import { reducer as checkInsReducer } from 'src/slices/checkins';
import { reducer as activitiesReducer } from 'src/slices/activities';
import { reducer as participantReducer } from 'src/slices/participant';
import { reducer as groupReducer } from 'src/slices/group';
import { reducer as groupTypeReducer } from 'src/slices/groupType';
import { reducer as contestReducer } from 'src/slices/contest';
import { reducer as transactionReducer } from 'src/slices/transaction';
import { reducer as participationCodeReducer } from 'src/slices/participation_code';
import { reducer as checkInImageReducer } from 'src/slices/check_in_image';
import { reducer as postReportsReducer } from 'src/slices/post_report';
import { reducer as postsReducer } from 'src/slices/post';
import { reducer as newsReducer } from 'src/slices/news';
import { reducer as appStateReducer } from 'src/slices/app_state';
import { reducer as participationDataTypeReducer } from 'src/slices/participation_data_type';

const rootReducer = combineReducers({
  form: formReducer,
  adminUser: adminUserReducer,
  location: locationReducer,
  checkIns: checkInsReducer,
  checkInImage: checkInImageReducer,
  activities: activitiesReducer,
  participant: participantReducer,
  group: groupReducer,
  groupType: groupTypeReducer,
  contest: contestReducer,
  postReports: postReportsReducer,
  posts: postsReducer,
  news: newsReducer,
  transaction: transactionReducer,
  participationCode: participationCodeReducer,
  appState: appStateReducer,
  participationDataType: participationDataTypeReducer
});

export default rootReducer;
