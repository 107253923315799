import { validation } from 'src/assets/locale';

// Create error string with localized message and limit value
const getValidationError = (localizedMsg, limitValue) => {
  return `${localizedMsg.replace('<fieldLimit>', limitValue) }`;
};

// STRING ERRORS
const errorStringMax = (value) => {
  return getValidationError(validation.fieldStringMax, value.max);
};

// NUMBER ERRORS
const errorNumberMin = (value) => {
  return getValidationError(validation.fieldNumberMin, value.min);
};

const errorNumberMax = (value) => {
  return getValidationError(validation.fieldNumberMax, value.max);
};

// Returns a custom yup localization object
const getYupLocalization = () => {
  return {
    string: {
      max: errorStringMax,
      email: validation.fieldStringEmail
    },
    number: {
      min: errorNumberMin,
      max: errorNumberMax,
      positive: validation.fieldNumberPositive,
      integer: validation.fieldNumberInteger
    },
    date: {
      min: validation.fieldDateMin,
      max: validation.fieldDateMax
    }
  };
};

// Returns a custom Material UI localization object
const getMUIDatePickerLocalization = () => {
  return {
    invalidDateMessage: validation.fieldDateValid,
    minDateMessage: validation.fieldDateMin,
    maxDateMessage: validation.fieldDateMax
  };
};

export {getYupLocalization, getMUIDatePickerLocalization};