import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  checkInImages: [],
  selectedCheckInImage: null,
  isFetching: false
};

const slice = createSlice({
  name: 'check_in_image',
  initialState,
  reducers: {
    getCheckInImages(state, action) {
      const checkInImages = action.payload;

      checkInImages.sort((a, b) => {
        if (a.insertedAt < b.insertedAt) {
          return 1;
        }

        if (a.insertedAt > b.insertedAt) {
          return -1;
        }

        return 0;
      });

      state.checkInImages = checkInImages;
    },
    removeCheckInImage(state, action) {
      const { id } = action.payload;

      state.checkInImages = state.checkInImages.filter(
        checkInImage => checkInImage.id !== id
      );
    },
    setCheckInImages(state, action) {
      state.checkInImages = action.payload;
    },
    setSelectedCheckInImage(state, action) {
      state.checkInImages = action.payload;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getCheckInImages = contestId => async dispatch => {
  dispatch(slice.actions.setFetching({ isFetching: true }));

  const query = `{
    contest {
        checkInImages {
            id,
            checkInId
            insertedAt
            image {
                thumbnail
                small
                medium
                large
                original
            }
            location {
                id,
                name
            }
            user {
                id
                firstName
                lastName
            }
        }
    }
}`;

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(
    slice.actions.getCheckInImages(response.data.data.contest.checkInImages)
  );
};

export const setSelectedCheckInImage = checkInImage => async dispatch => {
  dispatch(slice.actions.setSelectedCheckInImage({ checkInImage }));
};

export const removeCheckInImage = id => async dispatch => {
  dispatch(slice.actions.removeCheckInImage({ id }));
};

export const clearCheckInImages = () => async dispatch => {
  dispatch(slice.actions.setCheckInImages({ checkInImages: [] }));
};

export default slice;
