import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appState: {
    searchOpen: false
  },
  isFetching: false
};

const slice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updateAppState(state, action) {
      const { change } = action.payload;

      state.appState = {
        ...state.appState,
        ...change
      };
    }
  }
});

export const { reducer } = slice;

export const updateAppState = change => async dispatch => {
  dispatch(slice.actions.updateAppState({ change }));
};

export default slice;
