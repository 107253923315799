import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  locations: [],
  selectedLocation: null,
  isFetching: false,
  fetched: false
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getLocations(state, action) {
      const { locations } = action.payload;

      const collator = new Intl.Collator('no', {
        numeric: true,
        sensitivity: 'base'
      });

      locations.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        return collator.compare(nameA, nameB);
      });

      state.fetched = true;
      state.locations = locations;
    },
    setLocations(state, action) {
      const { locations } = action.payload;

      state.locations = locations;
    },
    setSelectedLocation(state, action) {
      const { location } = action.payload;

      state.selectedLocation = location;
    },
    setFetched(state, action) {
      state.fetched = action.payload;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    },
    deleteLocation(state, action) {
      const { locationIds } = action.payload;
      state.locations = state.locations.filter(
        x => locationIds.indexOf(x.id) === -1
      );
    },
    activateLocation(state, action) {
      const { locationIds } = action.payload;
      state.locations = state.locations.map(x =>
        locationIds.indexOf(x.id) !== -1 ? { ...x, active: true } : { ...x }
      );
    },
    deactivateLocation(state, action) {
      const { locationIds } = action.payload;
      state.locations = state.locations.map(x =>
        locationIds.indexOf(x.id) !== -1 ? { ...x, active: false } : { ...x }
      );
    }
  }
});

export const { reducer } = slice;

export const setSelectedLocation = location => async dispatch => {
  dispatch(slice.actions.setSelectedLocation({ location }));
};

export const getLocations = contestId => async dispatch => {
  const query = `
    {
      locations{
        id,
        contestId,
        name,
        notificationText,
        notificationSeverity,
        active,
        points,
        qrCode,
        checkInCode,
        checkInCount,
        dateFrom,
        dateTo,
        locationImages{
          title,
          description
          image{
            small,
            medium
          }
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getLocations(response.data.data));
};

export const deleteLocation = (contestId, locationIds) => async dispatch => {
  const res = await axios.post(`/admin/${contestId}/locations/delete`, {
    location_ids: locationIds
  });

  if (
    res.data &&
    res.data.status &&
    res.data.status.toLowerCase() === 'error'
  ) {
    throw Error(res.data.message);
  } else {
    dispatch(slice.actions.deleteLocation({ locationIds }));
  }
};

const changeActivationStatus = (
  contestId,
  locationIds,
  status
) => async dispatch => {
  const res = await axios.post(`/admin/${contestId}/locations/activation`, {
    location_ids: locationIds,
    status
  });

  if (
    res.data &&
    res.data.status &&
    res.data.status.toLowerCase() === 'error'
  ) {
    throw Error(res.data.message);
  } else if (status) {
    dispatch(slice.actions.activateLocation({ locationIds }));
  } else {
    dispatch(slice.actions.deactivateLocation({ locationIds }));
  }
};

export const copyLocationToContest = (contestId, locationId) => async () => {
  await axios.post(`/admin/${contestId}/locations/copy/${locationId}`);
};

export const activateLocation = (contestId, locationIds) => {
  return changeActivationStatus(contestId, locationIds, true);
};

export const deactivateLocation = (contestId, locationIds) => {
  return changeActivationStatus(contestId, locationIds, false);
};

export const clearLocations = () => async dispatch => {
  dispatch(slice.actions.setFetched(false));
  dispatch(slice.actions.setLocations({ locations: [] }));
};

export default slice;
