import moment from 'moment';
import mock from 'src/utils/mock';

const participants = [
  {
    id: 1,
    firstName: 'Sofia',
    surname: 'Jensen',
    fullName: 'Sofia Jensen',
    email: 'sofia@jensen.no',
    phone: '11223344',
    birthYear: 1991,
    trips: 1,
    uniqueTrips: 1,
    points: 1,
    rank: 3,
    registered: moment()
      .subtract(1, 'months')
      .hour(18)
      .minute(0),
    team: {
      id: 1,
      name: 'Summit'
    }
  },
  {
    id: 2,
    firstName: 'Stig',
    surname: 'Ovesen',
    fullName: 'Stig Ovesen',
    email: 'stig@ovesen.no',
    phone: '55667788',
    birthYear: 1972,
    trips: 2,
    uniqueTrips: 2,
    points: 2,
    rank: 1,
    registered: moment()
      .subtract(2, 'weeks')
      .hour(19)
      .minute(10),
    team: {
      id: 1,
      name: 'Summit'
    }
  },
  {
    id: 3,
    firstName: 'Cecilie',
    surname: 'Gunvaldsen',
    fullName: 'Cecilie Gunvaldsen',
    email: 'cecilie@gunvaldsen.no',
    phone: '12345678',
    birthYear: 1985,
    trips: 1,
    uniqueTrips: 1,
    points: 1,
    rank: 4,
    registered: moment()
      .subtract(1, 'weeks')
      .hour(21)
      .minute(30),
    team: {
      id: 1,
      name: 'Summit'
    }
  },
  {
    id: 4,
    firstName: 'Alexander',
    surname: 'Gulbrandsen',
    fullName: 'Alexander Gulbrandsen',
    email: 'alexander@gulbrandsen.no',
    phone: '11111111',
    birthYear: 1961,
    trips: 1,
    uniqueTrips: 1,
    points: 1,
    rank: 5,
    registered: moment()
      .subtract(12, 'days')
      .hour(14)
      .minute(40),
    team: {
      id: 2,
      name: 'Sprækingan'
    }
  },
  {
    id: 5,
    firstName: 'Benjamin',
    surname: 'Ness',
    fullName: 'Benjamin Ness',
    email: 'benjamin@ness.no',
    phone: '22222222',
    birthYear: 1957,
    trips: 2,
    uniqueTrips: 1,
    points: 2,
    rank: 2,
    registered: moment()
      .subtract(2, 'days')
      .hour(23)
      .minute(51),
    team: {
      id: 2,
      name: 'Sprækingan'
    }
  }
];

mock.onGet('/api/participants').reply(() => {
  return [200, { participants }];
});

mock.onPost('/api/participants').reply(config => {
  const data = JSON.parse(config.data);
  data.id = Math.floor(Math.random() * 1000) + 4;
  participants.push(data);

  return [200];
});

export default participants;
