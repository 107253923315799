import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  adminUsers: [],
  myAccessLevel: null,
  selectedAdminUser: null,
  isFetching: false
};

const slice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    getAdminUsers(state, action) {
      const { adminUsers } = action.payload;

      state.adminUsers = adminUsers;
    },
    setAdminUsers(state, action) {
      const { adminUsers } = action.payload;

      state.adminUsers = adminUsers;
    },
    setSelectedAdminUser(state, action) {
      const { adminUser } = action.payload;

      state.selectedAdminUser = adminUser;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const setSelectedAdminUser = adminUser => async dispatch => {
  dispatch(slice.actions.setSelectedAdminUser({ adminUser }));
};

export const getAdminUsers = contestId => async dispatch => {
  const query = `
    {
      contest {
        adminUsers {
          id,
          accessLevel,
          contest {
            id,
            name
          },
          contestOrganizer {
            id,
            name
          },
          company {
            id,
            name
          }
          user{
            id,
            firstName,
            lastName,
            email,
            phone,
            profileImage{
              image{
                thumbnail
              }
            }
          }
        }
      }
    }
  `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.setFetching({ isFetching: false }));

  dispatch(slice.actions.getAdminUsers(response.data.data.contest));
};

export default slice;
