import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  participationDataTypes: [],
  isFetching: false,
  fetched: false
};

const slice = createSlice({
  name: 'participation_data_type',
  initialState,
  reducers: {
    getParticipationDataTypes(state, action) {
      const participationDataTypes = action.payload;

      state.participationDataTypes = participationDataTypes;
    },
    deleteParticipationDataType(state, action) {
      const { id } = action.payload;
      state.participationDataTypes = state.participationDataTypes.filter(
        participationDataType => participationDataType.id !== id
      );
    },
    setParticipationDataTypes(state, action) {
      state.participationDataTypes = action.payload;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    },
    setFetched(state, action) {
      state.fetched = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getParticipationDataTypes = contestId => async dispatch => {
  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.get(
    `/admin/${contestId}/participation_data_types`
  );

  dispatch(slice.actions.setFetching({ isFetching: false }));
  dispatch(slice.actions.setFetched(true));

  dispatch(slice.actions.setParticipationDataTypes(response.data.data));
};

export const getParticipationDataType = async (contestId, id) => {
  const response = await axios.get(
    `/admin/${contestId}/participation_data_types/${id}`
  );

  return response.data.data;
};

export const updateSortOrder = (
  contestId,
  participationDataTypes
) => async () => {
  try {
    await axios.post(`/admin/${contestId}/participation_data_types/sort`, {
      participation_data_type_ids: participationDataTypes.map(p => p.id)
    });

    return true;
  } catch (e) {
    return e.message;
  }
};

export const deleteParticipationDataType = participationDataType => async dispatch => {
  try {
    const response = await axios.delete(
      `/admin/${participationDataType.contest_id}/participation_data_types/${participationDataType.id}`
    );

    if (response.status !== 204) {
      return response.data.message;
    }

    dispatch(slice.actions.deleteParticipationDataType(participationDataType));

    return true;
  } catch (e) {
    return e.message;
  }
};

export default slice;
