import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
// import moment from 'moment';

const initialState = {
  activities: [],
  selectedUserActivities: [],
  isFetching: false
};

const slice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setUserActivities(state, action) {
      const { activities } = action.payload;

      state.selectedUserActivities = activities;
    },
    getUserActivities(state, action) {
      const { activities } = action.payload;

      state.selectedUserActivities = activities;
    },
    setFetching(state, action) {
      const { isFetching } = action.payload;

      state.isFetching = isFetching;
    }
  }
});

export const { reducer } = slice;

export const getUserActivities = (contestId, userId) => async dispatch => {
  const query = `
      {
        user(id: ${userId}){
          activities{
            id,
            points,
            source,
            comment,
            clientInsertedAt,
            insertedAt,
            updatedAt
          }
        }
      }
    `;

  dispatch(slice.actions.setFetching({ isFetching: true }));

  const response = await axios.post(`/admin/${contestId}/g`, { query });

  dispatch(slice.actions.getUserActivities(response.data.data.user));
  dispatch(slice.actions.setFetching({ isFetching: false }));
};

export const setUserActivities = activities => async dispatch => {
  dispatch(slice.actions.setUserActivities({ activities }));
};

export const clearUserActivities = () => async dispatch => {
  dispatch(slice.actions.setUserActivities({ activities: [] }));
};

export default slice;
