import mock from 'src/utils/mock';
import participants from './participants';

const teams = [
  {
    id: 1,
    name: 'Summit',
    participants: 3,
    type: 'Vennelag',
    trips: 4,
    uniqueTrips: 4,
    points: 3,
    rank: 1
  },
  {
    id: 2,
    name: 'Sprækingan',
    participants: 2,
    type: 'Vennelag',
    trips: 3,
    uniqueTrips: 2,
    points: 3,
    rank: 2
  }
];

const updateTeam = (id, data) => {
  const index = teams.findIndex(x => x.id === id);
  const old = teams.find(x => x.id === id);
  const updated = { ...old, ...data };
  teams[index] = updated;
};

mock.onGet('/api/teams').reply(() => {
  return [200, { teams }];
});

mock.onGet(/\/api\/teams\/\d+/).reply(config => {
  const array = config.url.split('/');
  const id = parseInt(array.pop(), 10);

  const team = teams.find(x => x.id === id);
  team.participantList = participants.filter(x => x.team.id === id);
  return [200, { team }];
});

mock.onPost('/api/teams').reply(config => {
  const data = JSON.parse(config.data);
  data.id = Math.floor(Math.random() * 1000) + 4;
  data.participants = 0;
  data.type = 'Vennelag';
  data.trips = 0;
  data.uniqueTrips = 0;
  data.points = 0;
  teams.push(data);

  return [200];
});

mock.onPut(/\/api\/teams\/\d+/).reply(config => {
  const array = config.url.split('/');
  const id = parseInt(array.pop(), 10);
  const data = JSON.parse(config.data);
  updateTeam(id, data);
  return [200];
});
